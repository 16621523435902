import { Button, Col, Form, Modal } from "react-bootstrap";
import SingleSummaryRow from "../FormsComponents/SingleSummaryRow";
import { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import CopyIcon from "../../Images/Logos/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CrossIcon from "../../Images/Logos/cross.svg";
import PlusIcon from "../../Images/Logos/plus-icon.svg";
import MinusIcon from "../../Images/Logos/minus-icon.svg";
import "./Summary.css";
import CheckSuccess from "../../Images/Logos/DeployedSuccess.svg";
import axios from "axios";
import TaxSummaryRow from "../FormsComponents/TaxSummaryRow";
import { convertCooldownTime, truncateText } from "../../Utils/helperFunction";
import { formatInteger } from "../../Utils/helperFunction";
import {
  getCurrentChainId,
  transferPayment,
  deployContract,
} from "../../ContractAction/PaymentContractAction.js";
import { scrollToTop, resetAll } from "../../Utils/helperFunction.js";
import { setResetPayment } from "../../Store/Slices/ResetPaymentSlice.js";
import { useNavigate } from "react-router";
import { setResetDownload } from "../../Store/Slices/ResetDownloadFileSlice.js";
import { IoWarningOutline } from "react-icons/io5";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider, useConfig, useAccount } from "wagmi";
import {
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  bscTestnet,
  baseSepolia,
  fantom,
} from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useLocation } from "react-router";

const queryClient = new QueryClient();

const projectId = "58a22d2bc1c793fc31c117ad9ceba8d9";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  fantom,
  bscTestnet,
  baseSepolia,
];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  themeMode: "dark",
  allWallets: "SHOW",
  themeVariables: {
    "--w3m-accent": "linear-gradient(rgb(19 107 127), rgb(6 141 114))",
    "--w3m-border-radius-master": "1.5px",
    "--w3m-button-border-color": "none",
  },
});

function Summary({ setTab, setStepper }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const generalApi = useSelector((store) => store.app.generalApi);
  const optionalApi = useSelector((store) => store.app.optionalApi);
  const taxesApi = useSelector((store) => store.app.taxesApi);
  const tokenSymbol = useSelector((store) => store.general.tokenSymbol);
  const tokenName = localStorage.getItem("tokenName") || "";
  const WalletAddress = localStorage.getItem("walletAddress") || "";
  const formData = JSON.parse(localStorage.getItem("formData")) || "";
  const antibotCoolDownTime = formData?.antibotCoolDownTime;
  console.log("Form Data Local in Summary", antibotCoolDownTime);

  const ID = localStorage.getItem("ID") || "";

  const [connected, setConnected] = useState(() => {
    return JSON.parse(localStorage.getItem("wagmi.io.metamask.disconnected"));
  });

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "wagmi.io.metamask.disconnected") {
        const newValue = JSON.parse(event.newValue);
        setConnected(newValue);
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newValue = JSON.parse(
        localStorage.getItem("wagmi.io.metamask.disconnected")
      );
      setConnected(newValue);
    }, 1000);

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [show, setShow] = useState(false);
  const [saltValue, setSaltValue] = useState(0.0);
  // Deploy Text State
  const [deployText, setDeployText] = useState("DEPLOY");
  const [deployContractBoolean, setDeployContractBoolean] = useState(false);

  // Copy to clipboard
  const [copyStatus, setCopyStatus] = useState(false);

  // Chain ID State Variable
  const [chainID, setChainID] = useState("");

  // Transcation Amount
  const [amount, setAmount] = useState();

  // State variable for checkbox
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  // state and function for New Custom Modal

  const [showOuterModal, setShowOuterModal] = useState(false);
  const [showInnerModal, setShowInnerModal] = useState(false);

  const handleOuterModalOpen = () => setShowOuterModal(true);
  const handleOuterModalClose = () => setShowOuterModal(false);
  const handleInnerModalOpen = () => setShowInnerModal(true);
  const handleInnerModalClose = () => setShowInnerModal(false);

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000);
  };

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await fetchUserBalance();
    await fetchEstimatedGasFee();
    setShow(true);
  };

  const [newData, setdata] = useState();

  const getSummaryData = useCallback(async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      const response = await fetch(
        `https://trendifytokens.io/api/files/getalltokenadata?tokenname=${tokenName}&walletaddress=${WalletAddress}&id=${ID}`,
        requestOptions
      );

      const responseResult = await response.json();
      if (responseResult.status == true) {
        setdata(responseResult?.data);
        generateSolidityCode();
      } else {
        // Handle the case when result is empty or undefined
        console.error("Result is empty or undefined");
      }
    } catch (error) {
      console.log(error);
    }
  }, [tokenName, WalletAddress, optionalApi, generalApi, taxesApi]);

  console.log("newdata", newData);

  useEffect(() => {
    if (tokenName) {
      getSummaryData();
    }
  }, [tokenName, WalletAddress, getSummaryData]);

  useEffect(() => {
    handleHitApiAndDownload();
  }, [newData]);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
  const [Ownable2StepFile, setOwnable2StepFile] = useState("");
  const [ERC20BurnableFile, setERC20BurnableFile] = useState("");
  const [ERC20PermitFile, setERC20PermitFile] = useState("");
  const [CoinDividendTrackerFile, setCoinDividendTrackerFile] = useState("");
  const [MintableFile, setMintableFile] = useState("");
  const [PausableFile, setPausableFile] = useState("");
  const [SafeERC20RemasteredFile, setSafeERC20RemasteredFile] = useState("");
  const [IUniswapV2FactoryFile, setIUniswapV2FactoryFile] = useState("");
  const [IntializableFile, setIntializableFile] = useState("");

  console.log(
    "Selected Files Before Function!!",
    Ownable2StepFile,
    ERC20BurnableFile,
    ERC20PermitFile,
    CoinDividendTrackerFile,
    MintableFile,
    PausableFile,
    SafeERC20RemasteredFile,
    IUniswapV2FactoryFile,
    IntializableFile
  );

  const generateSolidityCode = () => {
    let solidityCode = `// SPDX-License-Identifier: MIT
  \n pragma solidity ^0.8.25;
 
  \nimport {IERC20, ERC20} from "./ERC20.sol";`;

    const additionalImports = [];
    const contractExtensions = [];

    // Conditional imports and extensions
    if (
      newData?.tokensymbol !== ""
      //  newData?.foregintokenrecovery === true ||
      //  newData.tokenowner == true||
      //  newData?.supplyrecipitent == true || newData?.maxtransaction
    ) {
      additionalImports.push(
        'import {Ownable, Ownable2Step} from "./Ownable2Step.sol";'
      );
      contractExtensions.push("Ownable2Step");
      setOwnable2StepFile("Ownable2Step.sol");
    }
    if (
      (newData?.initialsupply !== 0 && newData?.burable) ||
      newData?.autoburtx === true
    ) {
      additionalImports.push(
        'import {ERC20Burnable} from "./ERC20Burnable.sol";'
      );
      contractExtensions.push("ERC20Burnable");
      setERC20BurnableFile("ERC20Burnable.sol");
    }
    if (
      newData?.permit === true ||
      (newData?.mintable === true && newData?.mintableinput !== "")
    ) {
      additionalImports.push('import {ERC20Permit} from "./ERC20Permit.sol";');
      contractExtensions.push("ERC20Permit");
      setERC20PermitFile("ERC20Permit.sol");
    }
    //  if (newData?.defaultexchange === true && newData?.defaultexchangeselect !=="" && newData.dividendtax === true ) {
    //   additionalImports.push('import {DividendTrackerFunctions} from "./CoinDividendTracker.sol";');
    //   contractExtensions.push("DividendTrackerFunctions");
    // }
    if (newData?.mintable === true && newData?.mintableinput !== "") {
      additionalImports.push('import {Mintable} from "./Mintable.sol";');
      contractExtensions.push("Mintable");
      setMintableFile("Mintable.sol");
    }
    if (newData?.pausable === true || newData?.pausable === false) {
      additionalImports.push('import {Pausable} from "./Pausable.sol";');
      contractExtensions.push("Pausable");
      setPausableFile("Pausable.sol");
    }
    if (newData?.foregintokenrecovery === true) {
      additionalImports.push(
        'import {SafeERC20Remastered} from "./SafeERC20Remastered.sol";'
      );
      setSafeERC20RemasteredFile("SafeERC20Remastered.sol");
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      additionalImports.push(
        'import {Initializable} from "./Initializable.sol";'
      );
      contractExtensions.push("Initializable");
      setIntializableFile("Initializable.sol");
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      additionalImports.push('import "./IUniswapV2Factory.sol";');
      additionalImports.push(`import "./IUniswapV2Pair.sol";`);
      additionalImports.push(`import "./IUniswapV2Router01.sol";`);
      additionalImports.push(`import "./IUniswapV2Router02.sol";`);
      setIUniswapV2FactoryFile("IUniswapV2Factory.sol");
    }

    if (additionalImports.length > 0) {
      solidityCode += "\n" + additionalImports.join("\n");
    }

    // Contract header
    solidityCode += `
 
    \ncontract ${capitalizeFirstLetter(newData?.tokenname)} is ERC20`;
    if (contractExtensions.length > 0) {
      solidityCode += `, ${contractExtensions.join(", ")}`;
    }
    solidityCode += ` {
    `;
    if (newData?.foregintokenrecovery === true) {
      solidityCode += `\n    using SafeERC20Remastered for IERC20;`;
    }
    if (newData?.blacklist === true) {
      solidityCode += `\n    mapping(address => bool) public blacklisted;`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    uint16 public swapThresholdRatio;`;
    }
    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === true
    ) {
      solidityCode += `\n    uint256 private _taxwalletPending; `;
    }
    if (
      newData?.defaultexchange === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    uint256 private _liquidityPending; `;
    }
    // if (
    //   newData?.defaultexchange === true &&
    //   newData?.defaultexchangeselect !== "" && newData?.Dividendtax
    // ) {
    //   solidityCode += `\n    uint256 private _rewardsPending; `;
    // }
    if (
      newData?.wallettaxes.length !== 0 &&
      (newData?.defaultexchange === true || newData?.defaultexchange === false)
    ) {
      solidityCode += `\n    address public taxwalletAddress;  `;
    }
    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === true
    ) {
      solidityCode += `\n    uint16[3] public taxwalletFees;`;
    }
    if (newData?.defaultexchange === true && newData?.autoburtx === true) {
      solidityCode += `\n    uint16[3] public autoBurnFees; `;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    uint16[3] public liquidityFees; `;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    mapping (address => bool) public isExcludedFromFees; `;
    }
    if (newData?.defaultexchange === true) {
      solidityCode += `\n    uint16[3] public totalFees; `;
    }
    if (
      newData?.defaultexchange === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    bool private _swapping;`;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    IUniswapV2Router02 public routerV2;`;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    address public pairV2;`;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    mapping (address => bool) public AMMPairs;`;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    mapping (address => bool) public isExcludedFromLimits;`;
    }
    if (newData?.maxamountperwallet === true) {
      solidityCode += `\n    uint256 public maxWalletAmount;`;
    }
    if (newData?.defaultexchange && newData?.maxtransactionlimit === true) {
      if (newData?.onbuyingcheck === true) {
        solidityCode += `\n    uint256 public maxBuyAmount;`;
      }
      if (newData?.onsellingcheck === true) {
        solidityCode += `\n    uint256 public maxSellAmount;`;
      }
      if (newData?.ontransferringcheck === true) {
        solidityCode += `\n    uint256 public maxTransferAmount;`;
      }
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    mapping (address => uint256) public lastTrade;`;
    }
    if (
      newData?.defaultexchange === true &&
      (newData?.antibotcooldown === true ||
        newData?.antibotcooldowninput !== "" ||
        newData?.maxtransactionlimit === true)
    ) {
      solidityCode += `\n    uint256 public tradeCooldownTime;  `;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.enbletradingfunction === true
    ) {
      solidityCode += `\n    bool public tradingEnabled;`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    mapping (address => bool) public isExcludedFromTradingRestriction;`;
    }
    //  if (newData?.defaultexchange === true && newData.dividendtax === true) {
    //    solidityCode += ` \n   uint16[3] public rewardsFees; `;
    //  }
    // State variables
    //  if (newData?.mintable === true) {
    //    solidityCode += `\n   bool public mintable = true;`;
    //  }
    if (
      newData?.defaultexchange === false &&
      newData?.maxtransactionlimit === true &&
      newData?.maxtransactionlimitinput !== ""
    ) {
      solidityCode += `\n    uint256 public maxtransactionlimit1;`;
    }

    if (
      newData?.defaultexchange === false &&
      newData?.autoburtx === true &&
      newData?.autoBurnTaxInput !== ""
    ) {
      solidityCode += `\n    uint16 public autoBurnFees1;
    uint16 public totalFees1;`;
    }
    ///////////////////walletax

    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    uint16 public taxwalletFees; ///walttax single
      uint16 public totalFees;`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    uint256 private _liquidityPending;`;
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////

    solidityCode += `\n`;
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (newData?.foregintokenrecovery === true) {
      solidityCode += `\n    error InvalidAmountToRecover(uint256 amount, uint256 maxAmount);`;
    }
    if (newData?.foregintokenrecovery === true) {
      solidityCode += `\n    error InvalidToken(address tokenAddress);`;
    }
    if (
      (newData?.defaultexchange === true ||
        newData?.defaultexchange === false) &&
      newData?.defaultexchangeselect !== "" &&
      newData?.blacklist === true
    ) {
      solidityCode += `\n    error TransactionBlacklisted(address from, address to);`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    error InvalidSwapThresholdRatio(uint16 swapThresholdRatio); `;
    }
    if (
      (newData?.wallettaxes.length !== 0 &&
        newData?.defaultexchange === true) ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    error InvalidTaxRecipientAddress(address account);`;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.taxesliquiditytax === true ||
      (newData?.autoburtx === true && newData?.autoBurnTaxInput === "") ||
      (newData?.wallettaxes.length !== 0 &&
        newData?.wallettaxes.walletTaxRateInput === 0)
    ) {
      solidityCode += `\n    error CannotExceedMaxTotalFee(uint16 buyFee, uint16 sellFee, uint16 transferFee);`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    error InvalidPair(address pair); `;
    }
    if (newData?.maxamountperwallet === true) {
      solidityCode += `\n    error MaxWalletAmountTooLow(uint256 maxWalletAmount, uint256 limit);`;
    }
    if (newData?.maxamountperwallet === true) {
      solidityCode += `\n    error CannotExceedMaxWalletAmount(uint256 maxWalletAmount);`;
    }
    if (newData?.defaultexchange && newData?.maxtransactionlimit === true) {
      solidityCode += `\n    error MaxTransactionAmountTooLow(uint256 maxAmount, uint256 limit); `;
    }
    if (
      newData?.wallettaxes.length !== 0 ||
      ((newData?.defaultexchange === false ||
        newData?.defaultexchange === true) &&
        newData?.maxtransactionlimit === true)
    ) {
      solidityCode += `\n    error CannotExceedMaxTransactionAmount(uint256 maxAmount);`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.antibotcooldown === true &&
      newData?.antibotcooldowninput !== ""
    ) {
      solidityCode += `\n    error InvalidTradeCooldownTime(uint256 tradeCooldownTime); `;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== "" &&
      // newData?.antibotcooldown === true &&
      newData.antibotcooldowninput !== ""
    ) {
      solidityCode += `\n    error AddressInCooldown(address account);`;
    }
    if (newData?.enbletradingfunction === true) {
      solidityCode += `\n    error TradingAlreadyEnabled();`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== "" &&
      newData?.enbletradingfunction === true
    ) {
      solidityCode += `\n    error TradingNotEnabled(); \n`;
    }
    if (
      newData?.defaultexchange === false &&
      newData?.maxtransactionlimit === true &&
      newData?.maxtransactionlimitinput !== ""
    ) {
      // if (newData.onbuyingcheck === true) {
      solidityCode += `\n    error MaxTransactionAmountTooLow1(uint256 maxAmount, uint256 limit);`;
    }

    if (
      newData?.defaultexchange === false &&
      (newData?.autoburtx === true || newData?.wallettaxes.length !== 0) &&
      newData?.walletTaxRateInput !== 0
    ) {
      solidityCode += `\n    error CannotExceedMaxTotalFee(uint16 _fee);`;
    }

    solidityCode += `\n `;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (newData?.blacklist === true) {
      solidityCode += `\n    event BlacklistUpdated(address indexed account, bool isBlacklisted); `;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    event SwapThresholdUpdated(uint16 swapThresholdRatio);`;
    }

    if (
      newData?.wallettaxes.length !== 0 &&
      (newData?.defaultexchange === true || newData?.defaultexchange === false)
    ) {
      solidityCode += `\n    event WalletTaxAddressUpdated(uint8 indexed id, address newAddress); `;
    }

    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === true
    ) {
      solidityCode += `\n    event WalletTaxFeesUpdated(uint8 indexed id, uint16 buyFee,uint16 sellFee,uint16 transferFee); `;
    }
    if (newData?.defaultexchange === true && newData?.autoburtx === true) {
      solidityCode += `\n    event AutoBurnFeesUpdated(uint16 buyFee, uint16 sellFee, uint16 transferFee); `;
    }
    if (
      newData?.defaultexchange === false &&
      newData?.autoburtx === true &&
      newData?.autoBurnTaxInput !== ""
    ) {
      solidityCode += `\n     event AutoBurnFeesUpdated(uint16 Autoburnfee); `;
    }
    if (newData?.autoburtx === true) {
      solidityCode += `\n    event AutoBurned(uint256 amount);`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.taxesliquiditytax === true
    ) {
      solidityCode += `\n    event LiquidityFeesUpdated(uint16 buyFee, uint16 sellFee, uint16 transferFee);`;
    }
    if (
      newData?.defaultexchange === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    event LiquidityAdded(uint amountToken, uint amountCoin, uint liquidity);
    event ForceLiquidityAdded(uint256 leftoverTokens, uint256 unaddedTokens);`;
    }
    //  if (newData?.defaultexchange === true && newData.dividendtax === true) {
    //    solidityCode += `\n   event RewardsFeesUpdated(uint16 buyFee, uint16 sellFee, uint16 transferFee);`;
    //  }
    // if (
    //   newData?.defaultexchange === true &&
    //   newData?.defaultexchangeselect !== ""
    // ) {
    //   solidityCode += `\n    event RewardsSent(uint256 amount);`;
    // }
    if (newData?.maxamountperwallet === true) {
      solidityCode += `\n    event MaxWalletAmountUpdated(uint256 maxWalletAmount);`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.maxtransactionlimit === true
    ) {
      // if (newData.onbuyingcheck === true) {
      solidityCode += `\n    event MaxBuyAmountUpdated(uint256 maxBuyAmount);`;
      // }
      if (newData?.onsellingcheck === true) {
        solidityCode += `\n    event MaxSellAmountUpdated(uint256 maxSellAmount);`;
      }
      if (newData?.ontransferringcheck === true) {
        solidityCode += `\n    event MaxTransferAmountUpdated(uint256 maxTransferAmount);`;
      }
    }
    if (
      newData?.defaultexchange === true &&
      newData?.antibotcooldown === true &&
      newData?.antibotcooldowninput !== ""
    ) {
      solidityCode += `\n    event TradeCooldownTimeUpdated(uint256 tradeCooldownTime); `;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.enbletradingfunction === true
    ) {
      solidityCode += `\n    event TradingEnabled();`;
    }

    //////////////////router///////////////////////////////////
    ///////////////////////////////////////walllettax////////////////////////
    // if (
    //   newData?.wallettaxes.length !== 0 &&
    //   newData?.defaultexchange === true
    // ) {
    //   solidityCode += `\n    error CannotExceedMaxTotalFee(uint16 buyFee, uint16 sellFee, uint16 transferFee);`
    // }

    /////////////////////////////////////////////////////beforetrnasfer////////
    //////// Events /////////

    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    event RouterV2Updated(address indexed routerV2);
    event AMMPairsUpdated(address indexed AMMPair, bool isPair); `;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    event ExcludeFromLimits(address indexed account, bool isExcluded); `;
    }

    //////////////////router///////////////////////////////////
    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    event ExcludeFromFees(address indexed account, bool isExcluded);`;
    }

    if (
      newData?.defaultexchange === false &&
      newData?.maxtransactionlimit === true &&
      newData?.maxtransactionlimitinput !== ""
    ) {
      // if (newData.onbuyingcheck === true) {
      solidityCode += `\n    event MaxBuyAmountUpdated(uint256 maxtransactionlimit1); `;
    }

    ///////////////////wallet tax////////////////////

    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n     event WalletTaxFeesUpdated(uint16 _fees);`;
    }
    if (
      newData?.defaultexchange === true &&
      newData.enbletradingfunction === true
    ) {
      solidityCode += `\n    event ExcludeFromTradingRestriction(address indexed account, bool isExcluded);`;
    }
    if (
      newData?.wallettaxes.length !== 0 &&
      (newData?.defaultexchange === false || newData?.defaultexchange === true)
    ) {
      solidityCode += `\n    event WalletTaxSent(uint8 indexed id, address recipient, uint256 amount);`;
    }
    /////////////////////////////////////////////////////////
    solidityCode += `
      \n    constructor() 
        ERC20(unicode"${newData?.tokenname}", unicode"${newData?.tokensymbol}")`;

    if (contractExtensions.includes("Ownable2Step")) {
      solidityCode += `\n        Ownable(msg.sender)`;
    }
    if (newData?.mintable === true && newData?.mintableinput !== "") {
      solidityCode += `\n        Mintable(${newData?.mintableinput})`;
    }
    if (
      newData?.permit === true ||
      (newData?.mintable === true && newData?.mintableinput !== "")
    ) {
      solidityCode += `\n        ERC20Permit(unicode"${newData.tokenname}")`;
    }
    solidityCode += `\n    {`;
    if (newData?.supplyrecipitent === false) {
      solidityCode += `\n    address supplyRecipient = msg.sender ;`;
    }
    if (newData?.supplyrecipitent === true) {
      solidityCode += `\n    address supplyRecipient = ${newData?.supplyrecipitentinput};`;
    }
    if (
      newData?.defaultexchange === true &&
      newData.taxesliquiditytax === true
    ) {
      solidityCode += `\n    updateSwapThreshold(${newData.swapthresholdratio});`;
    }
    if (
      newData?.wallettaxes.length !== 0 &&
      (newData?.defaultexchange === true || newData?.defaultexchange === false)
    ) {
      solidityCode += `\n    taxwalletAddressSetup(${newData?.wallettaxes[0].walletRecipientAddress});`;
    }
    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === true
    ) {
      solidityCode += `\n    taxwalletFeesSetup(${newData?.wallettaxes[0].walletBuyInput}, ${newData?.wallettaxes[0].walletSellInput}, ${newData?.wallettaxes[0].walletTransferRateInput});    `;
    }
    if (newData?.defaultexchange === true && newData?.autoburtx === true) {
      solidityCode += `\n    autoBurnFeesSetup(${newData.autoburnbuytaxrateinput}, ${newData.autoburnselltaxrateinput}, ${newData.autoburntransfertaxrateinput});`;
    }
    if (
      newData?.defaultexchange === true &&
      newData.taxesliquiditytax === true
    ) {
      solidityCode += `\n     liquidityFeesSetup(${newData.liquiditybuytaxrateinput}, ${newData.liquidityselltaxrateinput}, ${newData.liquiditytransfertaxrateinput});`;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    excludeFromFees(supplyRecipient, true);
    excludeFromFees(address(this), true);   `;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    _excludeFromLimits(supplyRecipient, true);
    _excludeFromLimits(address(this), true);
    _excludeFromLimits(address(0), true); `;
    }

    if (newData?.maxamountperwallet === true) {
      solidityCode += `\n    updateMaxWalletAmount(${newData?.maxamountperwalletinput} * (10 ** decimals()) / 10);`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.maxtransactionlimit === true
    ) {
      if (newData.onbuyingcheck === true) {
       solidityCode += `\n    updateMaxBuyAmount(${newData?.onbuyingcheckinput} * (10 ** decimals()) / 10);`;
      }
      if (newData.onsellingcheck === true) {
        solidityCode += `\n    updateMaxSellAmount(${newData?.onsellingcheckinput} * (10 ** decimals()) / 10);`;
      }
      if (newData.ontransferringcheck === true) {
        solidityCode += `\n    updateMaxTransferAmount(${newData?.ontransferringcheckinput} * (10 ** decimals()) / 10);`;
      }
    }
    if (
      newData?.defaultexchange === true &&
      newData.antibotcooldown === true &&
      newData.antibotcooldowninput !== ""
    ) {
      solidityCode += `\n    updateTradeCooldownTime(${newData?.antibotcooldowninput});`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.enbletradingfunction === true
    ) {
      solidityCode += `\n    excludeFromTradingRestriction(supplyRecipient, true);
    excludeFromTradingRestriction(address(this), true);`;
    }
    if (
      newData?.defaultexchange === false &&
      newData?.autoburtx === true &&
      newData?.autoBurnTaxInput !== ""
    ) {
      solidityCode += `\n    autoBurnFeesSetup1(${newData?.autoBurnTaxInput}); `;
    }

    //////////////////////////////wallettax///////////////////////////

    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n     taxwalletFeesSetup(${newData?.wallettaxes[0].walletTaxRateInput});`;
    }
    //////////////////////////wallettax///////////////////////
    if (
      newData?.defaultexchange === false &&
      newData?.maxtransactionlimit === true &&
      newData?.maxtransactionlimitinput !== ""
    ) {
      // if (newData.onbuyingcheck === true) {
      solidityCode += `\n    updatemaxtransactionlimit1((${newData?.maxtransactionlimitinput}*(10**decimals()))/10); `;
    }
    //////////////////////////////////////////////////////////////////
    //  if (newData?.defaultexchange === true && newData?.defaultexchangeselect !=="" && newData.dividendtax === true ) {
    //   solidityCode += `\n    _deployDividendTracker(7200, 4000000 * (10 ** decimals()) / 10, 2300);
    //     gasForProcessingSetup(${newData?.gasautoclaim});
    //     rewardsFeesSetup(${newData.dividendbuytaxrateinput},${newData.dividendselltaxrateinput},${newData.dividendtransfertaxrateinput});
    //     _excludeFromDividends(supplyRecipient, true);
    //     _excludeFromDividends(address(this), true);
    //     _excludeFromDividends(address(dividendTracker), true); `;
    //  }

    //  if (newData?.defaultexchange === true && newData.dividendtax === true) {
    //    solidityCode += `\n   rewardsFeesSetup(${newData.dividendbuytaxrateinput},${newData.dividendselltaxrateinput},${newData.dividendtransfertaxrateinput}); `;
    //  }
    if (newData?.supplyrecipitent === true) {
      solidityCode += `\n    _mint(supplyRecipient, ${newData?.initialsupply} * (10 ** decimals()));\n`;
    } else if (newData?.initialsupply !== 0) {
      solidityCode += `\n    _mint(msg.sender, ${newData?.initialsupply} * (10 ** decimals()));\n`;
    }
    if (newData?.tokenowner === true) {
      solidityCode += `\n    _transferOwnership(${newData?.tokenownerinput}); `;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== "" &&
      newData?.customexchangeinput === ""
    ) {
      solidityCode += `\n    _updateRouterV2(${newData?.routeraddress});`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== "" &&
      newData?.customexchangeinput !== ""
    ) {
      solidityCode += `\n    _updateRouterV2(${newData?.customexchangeinput});`;
    }

    solidityCode += `
    }\n`;
    // Functions
    // if (
    //   newData?.defaultexchange === true &&
    //   newData?.defaultexchangeselect !== ""
    // ) {
    //   solidityCode += `\n    function afterConstructor(address _router) initializer external {
    //     _updateRouterV2(_router);
    // }\n`;
    // }

    if (newData?.decimals !== 0) {
      solidityCode += `\n    function decimals() public pure override returns (uint8) {
       return ${newData?.decimals};
    }\n`;
    }

    if (newData?.pausable === true) {
      solidityCode += `\n    function pause() public onlyOwner {
       _pause();
    }
 
    function unpause() public onlyOwner {
       _unpause();
    } \n`;
    }

    if (newData?.foregintokenrecovery === true) {
      solidityCode += `\n    function recoverToken(uint256 amount) external onlyOwner {
       uint256 maxRecoverable = balanceOf(address(this))`;
      if (
        newData?.defaultexchange === false &&
        newData?.defaultexchangeselect === ""
      ) {
        solidityCode += `;`;
      }
      if (
        newData?.defaultexchange === true &&
        newData?.defaultexchangeselect !== ""
      ) {
        solidityCode += ` - getAllPending();`;
      }
      solidityCode += `if (amount > maxRecoverable) revert InvalidAmountToRecover(amount, maxRecoverable);
       _transfer(address(this), msg.sender, amount);
    }\n`;
    }

    if (newData?.foregintokenrecovery === true) {
      solidityCode += `\n    function recoverForeignERC20(address tokenAddress, uint256 amount) external onlyOwner {
       if (tokenAddress == address(this)) revert InvalidToken(tokenAddress);
 
       IERC20(tokenAddress).safeTransfer(msg.sender, amount);
    } `;
    }

    if (newData?.blacklist === true) {
      solidityCode += `\n    function blacklist(address account, bool isBlacklisted) external onlyOwner {
       blacklisted[account] = isBlacklisted;
       emit BlacklistUpdated(account, isBlacklisted);
    } \n`;
    }

    if (
      newData?.defaultexchange === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    function _swapTokensForCoin(uint256 tokenAmount) private {
        address[] memory path = new address[](2);
        path[0] = address(this);
        path[1] = routerV2.WETH();
        _approve(address(this), address(routerV2), tokenAmount);
        routerV2.swapExactTokensForETHSupportingFeeOnTransferTokens(tokenAmount, 0, path, address(this), block.timestamp);
    }\n`;
    }

    if (
      newData?.defaultexchange === false &&
      newData?.maxtransactionlimit === true &&
      newData?.maxtransactionlimitinput !== ""
    ) {
      solidityCode += `\n    function updatemaxtransactionlimit1(uint _transactionlimitamount) public onlyOwner{
          if (_transactionlimitamount < _maxTxSafeLimit())
          revert MaxTransactionAmountTooLow1(_transactionlimitamount, _maxTxSafeLimit());
          maxtransactionlimit1 = _transactionlimitamount;
          emit MaxBuyAmountUpdated(_transactionlimitamount);
    }\n`;
    }

    if (
      newData?.defaultexchange === true &&
      newData.taxesliquiditytax === true
    ) {
      solidityCode += `\n    function updateSwapThreshold(uint16 _swapThresholdRatio) public onlyOwner {
         if (_swapThresholdRatio == 0 || _swapThresholdRatio > 500) revert InvalidSwapThresholdRatio(_swapThresholdRatio);
 
         swapThresholdRatio = _swapThresholdRatio;
         
         emit SwapThresholdUpdated(_swapThresholdRatio);
    }\n`;
    }

    if (
      newData?.defaultexchange === true &&
      newData.taxesliquiditytax === true
    ) {
      solidityCode += `\n    function getSwapThresholdAmount() public view returns (uint256) {
         return balanceOf(pairV2) * swapThresholdRatio / 10000;
    }\n`;
    }

    if (
      (newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== "") && ((newData?.liquiditybuytaxrate === true ||  newData?.liquidityselltaxrate === true || newData?.liquiditytransfertaxrate == true)|| newData?.autoburtx===true || newData?.foregintokenrecovery === true)
    ) {
      solidityCode += `\n    function getAllPending() public view returns (uint256) { 
          return 0`;
      if (
        newData?.wallettaxes.length !== 0 &&
        newData?.defaultexchange === true
      ) {
        solidityCode += ` + _taxwalletPending`;
      }
      if (
        newData?.defaultexchange === true &&
        (newData?.liquiditybuytaxrate === true ||
          newData?.liquidityselltaxrate === true ||
          newData?.liquiditytransfertaxrate === true)
      ) {
        solidityCode += ` + _liquidityPending`;
      }
      solidityCode += `; 
    }
      `;
    }

    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    function taxwalletAddressSetup(address _newAddress) public onlyOwner {
        if (_newAddress == address(0))
          revert InvalidTaxRecipientAddress(address(0));

        taxwalletAddress = _newAddress;
        excludeFromFees(_newAddress, true);
        _excludeFromLimits(_newAddress, true);

        emit WalletTaxAddressUpdated(1, _newAddress);
    }
    
    function taxwalletFeesSetup(
        uint16 _buyFee,
        uint16 _sellFee,
        uint16 _transferFee
    ) public onlyOwner {
        totalFees[0] = totalFees[0] - taxwalletFees[0] + _buyFee;
        totalFees[1] = totalFees[1] - taxwalletFees[1] + _sellFee;
        totalFees[2] = totalFees[2] - taxwalletFees[2] + _transferFee;
        if (totalFees[0] > 2500 || totalFees[1] > 2500 || totalFees[2] > 2500)
            revert CannotExceedMaxTotalFee(
                totalFees[0],
                totalFees[1],
                totalFees[2]
            );
        taxwalletFees = [_buyFee, _sellFee, _transferFee];

        emit WalletTaxFeesUpdated(1, _buyFee, _sellFee, _transferFee);
    }
    `;
    }

    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.defaultexchange === false &&
      newData?.defaultexchangeselect === ""
    ) {
      solidityCode += `\n     function taxwalletAddressSetup(address _newAddress) public onlyOwner {
        if (_newAddress == address(0))
          revert InvalidTaxRecipientAddress(address(0));

        taxwalletAddress = _newAddress;
        excludeFromFees(_newAddress, true);
        _excludeFromLimits(_newAddress, true);

        emit WalletTaxAddressUpdated(1, _newAddress);
    }

    function taxwalletFeesSetup(uint16 _Fee) public onlyOwner {
        totalFees = totalFees - taxwalletFees + _Fee;
        if (totalFees> 2500) revert CannotExceedMaxTotalFee(totalFees);
        taxwalletFees = totalFees;
        emit WalletTaxFeesUpdated(totalFees);
    }
    `;
    }
    if (newData?.defaultexchange === true && newData?.autoburtx === true) {
      solidityCode += `\n    function autoBurnFeesSetup(uint16 _buyFee, uint16 _sellFee, uint16 _transferFee) public onlyOwner {
         totalFees[0] = totalFees[0] - autoBurnFees[0] + _buyFee;
         totalFees[1] = totalFees[1] - autoBurnFees[1] + _sellFee;
         totalFees[2] = totalFees[2] - autoBurnFees[2] + _transferFee;
         if (totalFees[0] > 2500 || totalFees[1] > 2500 || totalFees[2] > 2500) revert CannotExceedMaxTotalFee(totalFees[0], totalFees[1], totalFees[2]);
 
         autoBurnFees = [_buyFee, _sellFee, _transferFee];
 
         emit AutoBurnFeesUpdated(_buyFee, _sellFee, _transferFee);
    }\n `;
    }
    if (
      newData?.defaultexchange === false &&
      newData?.autoburtx === true &&
      newData?.autoBurnTaxInput !== ""
    ) {
      solidityCode += ` \n    function autoBurnFeesSetup1(uint16 _autoburnfeeinput) public onlyOwner {
        totalFees1 = totalFees1 - autoBurnFees1 + _autoburnfeeinput;
        if (totalFees1 > 2500 ) revert CannotExceedMaxTotalFee(totalFees1);
 
        autoBurnFees1 = _autoburnfeeinput;
 
        emit AutoBurnFeesUpdated(_autoburnfeeinput);
    }\n`;
    }
    if (
      newData?.defaultexchange === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += ` \n    function _swapAndLiquify(uint256 tokenAmount) private returns (uint256 leftover) {
        uint256 halfAmount = tokenAmount / 2;
        uint256 otherHalf = tokenAmount - halfAmount;
        _swapTokensForCoin(halfAmount);
        uint256 coinBalance = address(this).balance;
        if (coinBalance > 0) {
            (uint amountToken, uint amountCoin, uint liquidity) = _addLiquidity(otherHalf, coinBalance);
            emit LiquidityAdded(amountToken, amountCoin, liquidity);
            return otherHalf - amountToken;
        } else {
            return otherHalf;
        }
    }\n`;
    }
    if (
      newData?.defaultexchange === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    function _addLiquidity(uint256 tokenAmount, uint256 coinAmount) private returns (uint, uint, uint) {
        _approve(address(this), address(routerV2), tokenAmount);

        return routerV2.addLiquidityETH{value: coinAmount}(address(this), tokenAmount, 0, 0, address(0), block.timestamp);
    }

    function addLiquidityFromLeftoverTokens() external {
      uint256 leftoverTokens = balanceOf(address(this)) - getAllPending();
      uint256 unaddedTokens = _swapAndLiquify(leftoverTokens);
      emit ForceLiquidityAdded(leftoverTokens, unaddedTokens);
    }\n`;
    }
    if (
      newData?.defaultexchange === true &&
      newData.taxesliquiditytax === true
    ) {
      solidityCode += `\n    function liquidityFeesSetup(uint16 _buyFee, uint16 _sellFee, uint16 _transferFee) public onlyOwner {
         totalFees[0] = totalFees[0] - liquidityFees[0] + _buyFee;
         totalFees[1] = totalFees[1] - liquidityFees[1] + _sellFee;
         totalFees[2] = totalFees[2] - liquidityFees[2] + _transferFee;
         if (totalFees[0] > 2500 || totalFees[1] > 2500 || totalFees[2] > 2500) revert CannotExceedMaxTotalFee(totalFees[0], totalFees[1], totalFees[2]);
         liquidityFees = [_buyFee, _sellFee, _transferFee];
         emit LiquidityFeesUpdated(_buyFee, _sellFee, _transferFee);
    } \n`;
    }

    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    function excludeFromFees(address account, bool isExcluded) public onlyOwner {
        isExcludedFromFees[account] = isExcluded;
        emit ExcludeFromFees(account, isExcluded); 
    }\n`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    function _updateRouterV2(address router) private {
        routerV2 = IUniswapV2Router02(router);
        pairV2 = IUniswapV2Factory(routerV2.factory()).createPair(address(this), routerV2.WETH()) ;\n`;
      //  if(newData?.defaultexchange === true && newData?.defaultexchangeselect !== "" && newData?.dividendtax=== true)
      //  solidityCode += `\n  _excludeFromDividends(router, true);
      //   \n`;

      solidityCode += `
        _excludeFromLimits(router, true);
        _setAMMPair(pairV2, true);
        emit RouterV2Updated(router);
    }\n`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    function setAMMPair(address pair, bool isPair) external onlyOwner {
         if (pair == pairV2) revert InvalidPair(pair);
         _setAMMPair(pair, isPair);
    }
 
    function _setAMMPair(address pair, bool isPair) private {
         AMMPairs[pair] = isPair;
         
         if (isPair) { 
         _excludeFromLimits(pair, true);
         }
         emit AMMPairsUpdated(pair, isPair);
    }\n`;
    }
    if (
      newData?.defaultexchange === true ||
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    function excludeFromLimits(address account, bool isExcluded) external onlyOwner {
        _excludeFromLimits(account, isExcluded);
    }
      
    function _excludeFromLimits(address account, bool isExcluded) internal {
      isExcludedFromLimits[account] = isExcluded;
      emit ExcludeFromLimits(account, isExcluded);
    }\n`;
    }
    if (newData?.defaultexchange === true) {
      solidityCode += `\n    function setPairForTaxation(address tokenB, bool isExcluded) external onlyOwner returns(address){
        address pairAddress = IUniswapV2Factory(routerV2.factory()).getPair(address(this), tokenB);
        if (pairAddress == address(0)) {
            pairAddress = IUniswapV2Factory(routerV2.factory()).createPair(address(this), tokenB);
        }
        _setAMMPair(pairAddress, isExcluded);
        return pairAddress;
    }\n`;
    }
    if (newData?.maxamountperwallet === true) {
      solidityCode += `\n    function updateMaxWalletAmount(uint256 _maxWalletAmount) public onlyOwner {
      if (_maxWalletAmount < _maxWalletSafeLimit()) revert MaxWalletAmountTooLow(_maxWalletAmount, _maxWalletSafeLimit());
      maxWalletAmount = _maxWalletAmount;
      emit MaxWalletAmountUpdated(_maxWalletAmount);
    }

    function _maxWalletSafeLimit() private view returns (uint256) {
       return totalSupply() / 1000;
    }\n`;
    }
    if (
      newData?.defaultexchange === (true || false) ||
      newData?.maxtransactionlimit === true ||
      newData?.maxtransactionlimitinput !== ""
    ) {
      solidityCode += `\n    function _maxTxSafeLimit() private view returns (uint256) {
      return totalSupply() * 5 / 10000;
    }\n`;
    }
    if (
      newData?.defaultexchange === (true || false) &&
      newData?.maxtransactionlimit === true
    ) {
      if (newData.onbuyingcheck === true) {
        solidityCode += `\n    function updateMaxBuyAmount(uint256 _maxBuyAmount) public onlyOwner {
       if (_maxBuyAmount < _maxTxSafeLimit()) revert MaxTransactionAmountTooLow(_maxBuyAmount, _maxTxSafeLimit());
       maxBuyAmount = _maxBuyAmount;
       emit MaxBuyAmountUpdated(_maxBuyAmount);
    }\n `;
      }

      if (newData.onsellingcheck === true) {
        solidityCode += `\n    function updateMaxSellAmount(uint256 _maxSellAmount) public onlyOwner {
       if (_maxSellAmount < _maxTxSafeLimit()) revert MaxTransactionAmountTooLow(_maxSellAmount, _maxTxSafeLimit());
       maxSellAmount = _maxSellAmount;
       emit MaxSellAmountUpdated(_maxSellAmount);
    }\n `;
      }

      if (newData.ontransferringcheck === true) {
        solidityCode += `\n    function updateMaxTransferAmount(uint256 _maxTransferAmount) public onlyOwner {
       if (_maxTransferAmount < _maxTxSafeLimit()) revert MaxTransactionAmountTooLow(_maxTransferAmount, _maxTxSafeLimit());
       maxTransferAmount = _maxTransferAmount;
       emit MaxTransferAmountUpdated(_maxTransferAmount);
    }\n `;
      }
    }
    if (
      newData?.defaultexchange === true &&
      newData.antibotcooldown === true &&
      newData.antibotcooldowninput !== ""
    ) {
      solidityCode += `\n    function updateTradeCooldownTime(uint256 _tradeCooldownTime) public onlyOwner {
        if (_tradeCooldownTime > 12 hours) revert InvalidTradeCooldownTime(_tradeCooldownTime);

        tradeCooldownTime = _tradeCooldownTime;
        
        emit TradeCooldownTimeUpdated(_tradeCooldownTime);
    } \n`;
    }
    if (
      newData?.defaultexchange === true &&
      newData.enbletradingfunction === true
    ) {
      solidityCode += `\n    function enableTrading() external onlyOwner {
       if (tradingEnabled) revert TradingAlreadyEnabled();
       tradingEnabled = true;
       emit TradingEnabled();
    }\n`;
    }
    if (
      newData?.defaultexchange === true &&
      newData.enbletradingfunction === true
    ) {
      solidityCode += `\n    function excludeFromTradingRestriction(address account, bool isExcluded) public onlyOwner {
        isExcludedFromTradingRestriction[account] = isExcluded;
        
        emit ExcludeFromTradingRestriction(account, isExcluded);
    }\n`;
    }

    ////////////////////////////////////////// on(taxwallet,autobrun,liquiditytax)/////////////////////////////////
    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.autoburtx === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true) &&
      newData?.defaultexchange === true
    ) {
      solidityCode += `\n    function _update(address from, address to, uint256 amount)
        internal
        override
    {
        _beforeTokenUpdate(from, to, amount);
        
        if (from != address(0) && to != address(0)) {
            if (!_swapping && amount > 0 && to != address(routerV2) && !isExcludedFromFees[from] && !isExcludedFromFees[to]) {
                uint256 fees = 0;
                uint8 txType = 3;
                
                if (AMMPairs[from]) {
                    if (totalFees[0] > 0) txType = 0;
                }
                else if (AMMPairs[to]) {
                    if (totalFees[1] > 0) txType = 1;
                }
                else if (totalFees[2] > 0) txType = 2;
                
                if (txType < 3) {
                    
                    uint256 autoBurnPortion = 0;

                    fees = amount * totalFees[txType] / 10000;
                    amount -= fees;
                    
                    _taxwalletPending += fees * taxwalletFees[txType] / totalFees[txType];

                    if (autoBurnFees[txType] > 0) {
                        autoBurnPortion = fees * autoBurnFees[txType] / totalFees[txType];
                        super._update(from, address(0), autoBurnPortion);
                        emit AutoBurned(autoBurnPortion);
                    }

                    _liquidityPending += fees * liquidityFees[txType] / totalFees[txType];

                    fees = fees - autoBurnPortion;
                }

                if (fees > 0) {
                    super._update(from, address(taxwalletAddress), fees);
                }
            }
            bool canSwap = getAllPending() >= getSwapThresholdAmount() && balanceOf(pairV2) > 0;

            if (!_swapping && !AMMPairs[from] && from != address(routerV2) && canSwap) {
                _swapping = true;
                
                if (false || _taxwalletPending > 0) {
                    uint256 token2Swap = 0 + _taxwalletPending;
                    bool success = false;
                    _swapTokensForCoin(token2Swap);
                    uint256 coinsReceived = address(this).balance;

                    uint256 taxwalletPortion = coinsReceived * _taxwalletPending / token2Swap;
                    if (taxwalletPortion > 0) {
                        (success,) = payable(taxwalletAddress).call{value: taxwalletPortion}("");
                        if (success) {
                            emit WalletTaxSent(1, taxwalletAddress, taxwalletPortion);
                        }
                    }
                    _taxwalletPending = 0;
                }
                if (_liquidityPending > 0) {
                    _liquidityPending = 0;
                }
                _swapping = false;
            }
          }
        super._update(from, to, amount);
        
        _afterTokenUpdate(from, to, amount);
    }\n`;
    }
    ///////////////////////////////////////////// off(taxwallet) ,on(autobrun,liquiditytax)//////////////////////////
    if (
      newData?.wallettaxes.length === 0 &&
      newData?.autoburtx === true &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n     function _update(
        address from,
        address to,
        uint256 amount
    ) internal override {
        _beforeTokenUpdate(from, to, amount);

        if (from != address(0) && to != address(0)) {
            if (
                !_swapping &&
                amount > 0 &&
                to != address(routerV2) &&
                !isExcludedFromFees[from] &&
                !isExcludedFromFees[to]
            ) {
                uint256 fees = 0;
                uint8 txType = 3;

                if (AMMPairs[from]) {
                    if (totalFees[0] > 0) txType = 0;
                } else if (AMMPairs[to]) {
                    if (totalFees[1] > 0) txType = 1;
                } else if (totalFees[2] > 0) txType = 2;

                if (txType < 3) {
                    uint256 autoBurnPortion = 0;
                    fees = (amount * totalFees[txType]) / 10000;
                    amount -= fees;
                    if (autoBurnFees[txType] > 0) {
                        autoBurnPortion =
                            (fees * autoBurnFees[txType]) /
                            totalFees[txType];
                        super._update(from, address(0), autoBurnPortion);
                        emit AutoBurned(autoBurnPortion);
                    }
                    _liquidityPending +=
                        (fees * liquidityFees[txType]) /
                        totalFees[txType];
                    fees = fees - autoBurnPortion;
                }
            }
            bool canSwap = getAllPending() >= getSwapThresholdAmount() &&
                balanceOf(pairV2) > 0;
            if (
                !_swapping &&
                !AMMPairs[from] &&
                from != address(routerV2) &&
                canSwap
            ) {
                _swapping = true;

                if (_liquidityPending > 0) {
                    _swapAndLiquify(_liquidityPending);
                    _liquidityPending = 0;
                }
                _swapping = false;
            }
        }
        super._update(from, to, amount);

        _afterTokenUpdate(from, to, amount);
    }\n`;
    }

    /////////////////////////////////////////////off(autoburntax),on(wallettax,liquiditytax)///////////////////////////////////////////
    if (
      newData?.wallettaxes.length !== 0 &&
      newData?.autoburtx === false &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    function _update(
        address from,
        address to,
        uint256 amount
    ) internal override {
        _beforeTokenUpdate(from, to, amount);

        if (from != address(0) && to != address(0)) {
            if (
                !_swapping &&
                amount > 0 &&
                to != address(routerV2) &&
                !isExcludedFromFees[from] &&
                !isExcludedFromFees[to]
            ) {
                uint256 fees = 0;
                uint8 txType = 3;

                if (AMMPairs[from]) {
                    if (totalFees[0] > 0) txType = 0;
                } else if (AMMPairs[to]) {
                    if (totalFees[1] > 0) txType = 1;
                } else if (totalFees[2] > 0) txType = 2;

                if (txType < 3) {
                    fees = (amount * totalFees[txType]) / 10000;
                    amount -= fees;

                    _taxwalletPending +=
                        (fees * taxwalletFees[txType]) /
                        totalFees[txType];

                    // Removed autoBurnPortion calculation and update
                    _liquidityPending +=
                        (fees * liquidityFees[txType]) /
                        totalFees[txType];
                }

                if (fees > 0) {
                    super._update(from, address(taxwalletAddress), fees);
                }
            }
            bool canSwap = getAllPending() >= getSwapThresholdAmount() &&
                balanceOf(pairV2) > 0;
            if (
                !_swapping &&
                !AMMPairs[from] &&
                from != address(routerV2) &&
                canSwap
            ) {
                _swapping = true;

                if (_taxwalletPending > 0) {
                    uint256 token2Swap = _taxwalletPending;

                    bool success = false;
                    _swapTokensForCoin(token2Swap);
                    uint256 coinsReceived = address(this).balance;

                    uint256 taxwalletPortion = (coinsReceived *
                        _taxwalletPending) / token2Swap;
                    if (taxwalletPortion > 0) {
                        (success, ) = payable(taxwalletAddress).call{
                            value: taxwalletPortion
                        }("");
                        if (success) {
                            emit WalletTaxSent(1,taxwalletAddress,taxwalletPortion
                          );
                        }
                    }
                    _taxwalletPending = 0;
                }
                if (_liquidityPending > 0) {
                    _swapAndLiquify(_liquidityPending);
                    _liquidityPending = 0;
                }
                _swapping = false;
            }
        }
        super._update(from, to, amount);
        _afterTokenUpdate(from, to, amount);
    }\n`;
    }

    /////////////////////////////////////////////// off(taxwallet,autobrun) on(liquiditytax)/////////////////////
    if (
      newData?.wallettaxes.length === 0 &&
      newData?.autoburtx === false &&
      (newData?.liquiditybuytaxrate === true ||
        newData?.liquidityselltaxrate === true ||
        newData?.liquiditytransfertaxrate === true)
    ) {
      solidityCode += `\n    function _update(address from, address to, uint256 amount) internal override {
        _beforeTokenUpdate(from, to, amount);
        
        if (from != address(0) && to != address(0)) {
            if (!_swapping && amount > 0 && to != address(routerV2) && !isExcludedFromFees[from] && !isExcludedFromFees[to]) {
                _swapping = true;

                uint256 swapAmount = getSwapThresholdAmount();
                if (swapAmount > 0 && _liquidityPending > 0) {
                    uint256 leftover = _swapAndLiquify(_liquidityPending);
                    if (leftover > 0) emit ForceLiquidityAdded(_liquidityPending, leftover);
                    _liquidityPending = 0;
                }
                _swapping = false;
            }

            bool canSwap = getAllPending() >= getSwapThresholdAmount() && balanceOf(pairV2) > 0;
            // Apply fees only if not excluded
            uint256 fee = 0;
            if (!isExcludedFromFees[from] && !isExcludedFromFees[to]) {
                if (AMMPairs[from]) {
                    fee = amount * liquidityFees[0] / 10000;
                } else if (AMMPairs[to]) {
                    fee = amount * liquidityFees[1] / 10000;
                } else {
                    fee = amount * liquidityFees[2] / 10000;
                }
                _liquidityPending += fee;
            }
            amount -= fee;
            if (fee > 0) {
                    super._update(from, address(this), fee);
            }
          }
        // Transfer tokens (including possible mint or burn operations)
        super._update(from, to, amount);

        _afterTokenUpdate(from, to, amount);
    }\n`;
    }
    ////////////////////////////////////// on(taxwallet,autobrun) off(liquiditytax)/////////////////////////////
    if (
      (newData?.wallettaxes.length !== 0 || newData?.autoburtx === true) &&
      newData?.liquiditybuytaxrate === false &&
      newData?.liquidityselltaxrate === false &&
      newData?.liquiditytransfertaxrate === false &&
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    function _update(
        address from,
        address to,
        uint256 amount
        ) internal override {`;
      if (
        newData?.defaultexchange === true &&
        newData?.maxtransactionlimit === true
      ) {
        solidityCode += `\n    _beforeTokenUpdate(from, to, amount);`;
      }
      solidityCode += `\n     if (from != address(0) && to != address(0)) {
            uint256 fees = 0;
            uint8 txType = 3;

            if (AMMPairs[from]) {
                // Buy transaction
                if (totalFees[0] > 0) txType = 0;
            } else if (AMMPairs[to]) {
                // Sell transaction
                if (totalFees[1] > 0) txType = 1;
            } else {
                // Transfer transaction
                if (totalFees[2] > 0) txType = 2;
            }
            if (txType < 3) {`;
      if (
        newData?.liquiditybuytaxrate === false &&
        newData?.liquidityselltaxrate === false &&
        newData?.liquiditytransfertaxrate === false &&
        newData?.defaultexchange === true &&
        newData?.autoburtx === true
      ) {
        solidityCode += `\n            uint256 autoBurnPortion = 0;`;
      }
      solidityCode += `\n            fees = (amount * totalFees[txType]) / 10000;
                amount -= fees;`;

      if (
        newData?.wallettaxes.length !== 0 &&
        newData?.liquiditybuytaxrate === false &&
        newData?.liquidityselltaxrate === false &&
        newData?.liquiditytransfertaxrate === false &&
        newData?.defaultexchange === true
      ) {
        solidityCode += `\n 
                uint256 taxwalletPortion = (fees * taxwalletFees[txType]) /
                    totalFees[txType];
                if (taxwalletPortion > 0) {
                    _taxwalletPending += taxwalletPortion;
                }`;
      }
      if (
        newData?.liquiditybuytaxrate === false &&
        newData?.liquidityselltaxrate === false &&
        newData?.liquiditytransfertaxrate === false &&
        newData?.defaultexchange === true &&
        newData?.autoburtx === true
      ) {
        solidityCode += `\n             if (autoBurnFees[txType] > 0) {
                    autoBurnPortion =
                    (fees * autoBurnFees[txType]) /
                    totalFees[txType];
                    super._update(from, address(0), autoBurnPortion);
                    emit AutoBurned(autoBurnPortion);
                }
                // Deduct the remaining fees
                // Deduct fees and transfer them to the contract address
                fees = fees - autoBurnPortion;`;
      }

      // Deduct fees and transfer them to the contract address
      solidityCode += `\n              if (fees > 0) {
                    super._update(from, address(this), fees);
                }
            }`;
      if (
        newData?.wallettaxes.length !== 0 &&
        newData?.liquiditybuytaxrate === false &&
        newData?.liquidityselltaxrate === false &&
        newData?.liquiditytransfertaxrate === false &&
        newData?.defaultexchange === true
      ) {
        solidityCode += `\n 
            // Process pending wallet tax
               if (_taxwalletPending > 0) {
                super._update(
                    address(this),
                    taxwalletAddress,
                    _taxwalletPending
                );
                emit WalletTaxSent(1, taxwalletAddress, _taxwalletPending);
                _taxwalletPending = 0;
            }`;
      }

      solidityCode += `\n       }
        super._update(from, to, amount);`;
      if (
        newData?.defaultexchange === true &&
        (newData?.maxamountperwallet === true ||
          newData?.maxtransactionlimit === true)
      ) {
        solidityCode += `\n      _afterTokenUpdate(from, to, amount);`;
      }
      solidityCode += `\n    }\n`;
    }

    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    function _beforeTokenUpdate(
        address from,
        address to,
        uint256 amount)
        internal view whenNotPaused{`;
      if (
        newData?.defaultexchange === true &&
        newData?.defaultexchangeselect !== "" &&
        newData?.blacklist === true
      ) {
        solidityCode += `\n          if (blacklisted[from] || blacklisted[to])
        revert TransactionBlacklisted(from, to); \n`;
      }
      if (
        newData?.defaultexchange === true &&
        newData?.defaultexchangeselect !== "" &&
        newData?.maxtransactionlimit === true
      ) {
        if (newData.onbuyingcheck === true) {
          solidityCode += `\n       if (
          AMMPairs[from] && !isExcludedFromLimits[to] && amount > maxBuyAmount
        ) {
            // BUY
          revert CannotExceedMaxTransactionAmount(maxBuyAmount);
        }\n`;
        }
        if (newData?.onsellingcheck === true) {
          solidityCode += `\n       if (
          AMMPairs[to] &&
          !isExcludedFromLimits[from] &&
          amount > maxSellAmount
        ) {
          // SELL
          revert CannotExceedMaxTransactionAmount(maxSellAmount);
          } \n`;
        }
        if (newData?.ontransferringcheck === true) {
          solidityCode += `\n      if (
          !AMMPairs[to] &&
          !isExcludedFromLimits[from] &&
          amount > maxTransferAmount
        ) {
            // OTHER
          revert CannotExceedMaxTransactionAmount(maxTransferAmount);
        } \n`;
        }
      }
      if (
        newData?.defaultexchange === true &&
        // newData?.defaultexchangeselect !== "" &&
        newData?.antibotcooldown === true &&
        newData.antibotcooldowninput !== ""
      ) {
        solidityCode += `\n       if (
          !isExcludedFromLimits[from] &&
          lastTrade[from] + tradeCooldownTime > block.timestamp
        ) revert AddressInCooldown(from);
       if (
          !isExcludedFromLimits[to] &&
          lastTrade[to] + tradeCooldownTime > block.timestamp
       ) revert AddressInCooldown(to); `;
      }
      if (
        newData?.defaultexchange === true &&
        newData.enbletradingfunction === true
      ) {
        solidityCode += `\n       if (
            (AMMPairs[from] && !isExcludedFromTradingRestriction[to]) ||
            ( AMMPairs[to] && !isExcludedFromTradingRestriction[from])
        ) 
        {
           if (!tradingEnabled) revert TradingNotEnabled();
        }  \n`;
      }
      solidityCode += `    \n     }\n`;
    }
    if (
      newData?.defaultexchange === true &&
      newData?.defaultexchangeselect !== ""
    ) {
      solidityCode += `\n    function _afterTokenUpdate(
        address from,
        address to,
        uint256 amount
    ) internal {`;
      solidityCode += `\n       if (from == address(0)) 
          {`;
      {
        if (newData?.maxamountperwallet === true) {
          solidityCode += `\n       if (maxWalletAmount < _maxWalletSafeLimit())
           updateMaxWalletAmount(_maxWalletSafeLimit());`;
        }
        if (newData?.onbuyingcheck === true) {
          solidityCode += `\n       if (maxBuyAmount < _maxTxSafeLimit())
            updateMaxBuyAmount(_maxTxSafeLimit());`;
        }
        if (newData?.onsellingcheck === true) {
          solidityCode += `\n       if (maxSellAmount < _maxTxSafeLimit())
            updateMaxSellAmount(_maxTxSafeLimit());`;
        }
        if (newData?.ontransferringcheck === true) {
          solidityCode += `\n       if (maxTransferAmount < _maxTxSafeLimit())
            updateMaxTransferAmount(_maxTxSafeLimit());`;
        }
        solidityCode += `\n       }`;
      }
      if (newData?.maxamountperwallet === true) {
        solidityCode += `\n       if (!isExcludedFromLimits[to] && balanceOf(to) > maxWalletAmount) {
            revert CannotExceedMaxWalletAmount(maxWalletAmount);
            }`;
      }
      solidityCode += `\n       if (AMMPairs[from] && !isExcludedFromLimits[to]) lastTrade[to] = block.timestamp;
           else if (AMMPairs[to] && !isExcludedFromLimits[from]) lastTrade[from] = block.timestamp; 
    }\n`;
    }
    ////////////// when
    if (
      newData?.wallettaxes?.length === 0 &&
      newData?.liquiditybuytaxrate === false &&
      newData?.autoburtx === false &&
      newData?.autoBurnTaxInput === "" &&
      (newData?.defaultexchange === true ||
        newData?.defaultexchange === false) &&
      (newData?.maxtransactionlimit === true ||
        newData?.maxamountperwallet === true)
    ) {
      solidityCode += `\n    function _update(
        address from,
        address to,
        uint256 amount
      ) internal override {`;
      if (
        (newData?.defaultexchange === true ||
          newData?.defaultexchange === false) &&
        (newData?.maxtransactionlimit === true ||
          newData?.antibotcooldown === true ||
          newData?.enbletradingfunction === true)
      ) {
        solidityCode += `\n    _beforeTokenUpdate(from, to, amount);\n`;
      }
      if (
        (newData?.defaultexchange === false ||
          newData?.defaultexchange === true) &&
        (newData?.maxamountperwallet === true ||
          newData?.maxtransactionlimit === true ||
          newData?.antibotcooldown === true)
      ) {
        solidityCode += `\n     _afterTokenUpdate(from, to, amount);\n`;
      }
      solidityCode += `\n }`;
    }

    if (
      (newData?.wallettaxes.length !== 0 ||
        (newData?.autoburtx === true && newData?.autoBurnTaxInput !== "")) &&
      newData?.defaultexchange === false
    ) {
      solidityCode += `\n    function _update(address from, address to, uint256 amount) internal override {`;
      if (
        newData?.defaultexchange === false &&
        newData?.maxtransactionlimit === true &&
        newData?.maxtransactionlimitinput !== ""
      ) {
        solidityCode += `\n         _beforeTokenUpdate(from, to, amount);`;
      }
      solidityCode += `\n         if (from != address(0) && to != address(0)) {
         uint256 fees = 0;`;
      // Calculate the auto burn fee
      if (newData?.autoburtx === true && newData?.autoBurnTaxInput !== "") {
        solidityCode += `\n         uint256 autoBurnFee = (amount * autoBurnFees1) / 10000;
         fees += autoBurnFee;`;
      }
      // Calculate the wallet tax fee
      if (newData?.wallettaxes.length !== 0) {
        solidityCode += `\n         uint256 walletTaxFee = (amount * taxwalletFees) / 10000;
         fees += walletTaxFee;`;
      }

      solidityCode += `\n         amount -= fees;`;

      // Transfer wallet tax to the tax wallet address
      if (newData?.autoburtx === true && newData?.autoBurnTaxInput !== "") {
        solidityCode += `\n         if (autoBurnFee > 0) {
        super._update(from, address(0), autoBurnFee);
        emit AutoBurned(autoBurnFee);
        }`;
      }

      if (newData?.wallettaxes.length !== 0) {
        solidityCode += `\n         if (walletTaxFee > 0) {
        super._update(from, taxwalletAddress, walletTaxFee);
        emit WalletTaxSent(1, taxwalletAddress, walletTaxFee);
        }`;
      }
      solidityCode += `\n      }`;
      if (
        newData?.defaultexchange === false &&
        (newData?.maxamountperwallet === true ||
          newData?.maxtransactionlimit === true)
      ) {
        solidityCode += `\n      _afterTokenUpdate(from, to, amount);`;
      }
      solidityCode += `\n      super._update(from, to, amount);
    }\n`;
    }

    if (
      newData?.defaultexchange === false &&
      (newData?.maxtransactionlimit === true ||
        newData?.maxtransactionlimit === true)
    ) {
      solidityCode += `\n    function _beforeTokenUpdate(address from, address to, uint256 amount)
        internal
        view
        whenNotPaused 
      {
      if (!isExcludedFromLimits[from] && amount > maxtransactionlimit1) { 
          revert CannotExceedMaxTransactionAmount(maxtransactionlimit1);
      }
    }`;
    }

    if (
      newData?.defaultexchange === false &&
      (newData?.maxamountperwallet === true ||
        newData?.maxtransactionlimit === true)
    ) {
      solidityCode += `\n    function _afterTokenUpdate(address from, address to, uint256 amount)
    internal
    {
        if (from == address(0)) {`;
      if (
        newData?.defaultexchange === false &&
        newData?.maxamountperwallet === true &&
        newData?.maxamountperwalletinput !== ""
      ) {
        solidityCode += `\n 
            if (maxWalletAmount < _maxWalletSafeLimit()) updateMaxWalletAmount(_maxWalletSafeLimit());`;
      }
      if (
        newData?.defaultexchange === false &&
        newData?.maxtransactionlimit === true &&
        newData?.maxtransactionlimitinput !== ""
      ) {
        solidityCode += `\n 
            if (maxtransactionlimit1 < _maxTxSafeLimit()) updatemaxtransactionlimit1(_maxTxSafeLimit());`;
      }
      solidityCode += `\n        }`;
      if (
        newData?.defaultexchange === false &&
        newData?.maxamountperwallet === true &&
        newData?.maxamountperwalletinput !== ""
      ) {
        solidityCode += `\n 
        if (!isExcludedFromLimits[to] && balanceOf(to) > maxWalletAmount) {
            revert CannotExceedMaxWalletAmount(maxWalletAmount);
        }`;
      }
      solidityCode += `\n    }`;
    }
    solidityCode += `\n }`;
    return solidityCode;
  };

  const compile = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestBody = {
      // token_name: localStorage.getItem("tokenName"),
      // wallet_address: localStorage.getItem("walletAddress"),
      token_name: newData?.tokenname,
      wallet_address: newData?.walletaddress,
      get_id: newData?.id,
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
    };

    fetch("https://trendifytokens.io/pyapi/compile_or_get_text", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text();
      })
      .then((result) => handleOuterModalOpen())
      .catch((error) => {
        console.error("Fetch error:", error);
        toast.error(
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p className="zeroMargin">Unable to Compile</p>
            <p className="zeroMargin">Try Again</p>
          </div>,
          {
            autoClose: 1050,
            style: {
              background: "#FF0000",
              color: "#ffffff",
            },
          }
        );
      });
  };

  const handlePrevious = () => {
    // if (txnStatus) {
    //   setTab("tab1");
    //   setStepper(0);
    //   setTxnStatus(false);
    // } else {
    //   setTab("tab3");
    //   setStepper(2);
    // }
    setTab("tab3");
    setStepper(2);
    scrollToTop();
    dispatch(setResetDownload(true));
  };

  const Options = ["Use Current Address"];

  const [selectedOption, setSelectedOption] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [deploymentError, setDeploymentError] = useState(null);
  const [accountBalance, setAccountBalance] = useState(0);
  const [estimatedGasFee, setEstimatedGasFee] = useState(0);
  const [trendSmartContractFee, setTrendSmartContractFee] = useState(0);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleIncrement = () => {
    setSaltValue((prev) => prev + 0.1);
  };

  const fetchEstimatedGasFee = async () => {
    const web3 = new Web3(window.ethereum);
    const gasPrice = await web3.eth.getGasPrice(); // in Wei
    // Estimate gas for deployment (assuming 8 million gas limit)
    const estimatedGas = 8000000;
    const estimatedGasFee =
      (parseFloat(gasPrice) * estimatedGas) / Math.pow(10, 18); // convert to Ether/MATIC
    setEstimatedGasFee(estimatedGasFee.toFixed(8)); // limit decimal points
  };

  const handleDecrement = () => {
    if (saltValue < 0.1) return;
    setSaltValue((prev) => prev - 0.1);
  };

  const [currency, setCurrency] = useState("---");
  // const [Chainid, setCurrentChainId] = useState('')
  const [CurrentChainname, setCurrentChainName] = useState("");

  const web3 = new Web3(window.ethereum);

  useEffect(() => {
    web3.eth.getChainId().then((chainId) => {
      setChainID(chainId);
      if (chainId) {
        let chainName = "Unknown Chain";
        switch (chainId.toString()) {
          case "1":
            chainName = "Ethereum";
            setCurrency("ETH");
            break;
          case "137":
            chainName = "Polygon";
            setCurrency("MATIC");
            break;
          case "56":
            chainName = "BNB Smart Chain Mainnet";
            setCurrency("BNB");
            break;
          case "43114":
            chainName = "Avalanche";
            setCurrency("AVAX");
            break;
          case "42161":
            chainName = "Arbitrum";
            setCurrency("ARB");
            break;
          case "10":
            chainName = "Optimism";
            setCurrency("OPT");
            break;
          case "42220":
            chainName = "Celo Mainnet";
            setCurrency("CELO");
            break;
          case "8453":
            chainName = "Base";
            setCurrency("ETH");
            break;
          case "97":
            chainName = "BSC Testnet";
            setCurrency("tBNB");
            break;
          case "43113":
            chainName = "Avalanche Fuji";
            setCurrency("FUJI");
            break;
          case "421614":
            chainName = "Arbitrum Sepolia";
            setCurrency("ETH");
            break;
          case "250":
            chainName = "Fantom Opera";
            setCurrency("FTM");
            break;
          case "84532":
            chainName = "Base Sepolia Testnet";
            setCurrency("ETH ");
            break;
          default:
            break;
        }
        setCurrentChainName(chainName);
      }
      if (chainId) {
        window.ethereum.on("chainChanged", async (chainId) => {
          setChainID(chainId);
          let chainName = "Unknown Chain";
          switch (chainId.toString()) {
            case "0x1" || "1":
              chainName = "Ethereum";
              setCurrency("ETH");
              break;
            case "0x89" || "137":
              chainName = "Polygon";
              setCurrency("MATIC");
              break;
            case "0xa86a" || "43114":
              chainName = "Avalanche";
              setCurrency("AVAX");
              break;
            case "0xa4b1" || "42161":
              chainName = "Arbitrum";
              setCurrency("ARB");
              break;
            case "0xa" || "10":
              chainName = "Optimism";
              setCurrency("OPT");
              break;
            case "0x38" || "56 ":
              chainName = "BNB Smart Chain Mainnet";
              setCurrency("BNB");
              break;
            case "42220" || "0xa4ec":
              chainName = "Celo Mainnet";
              setCurrency("CELO");
              break;

            case "0x2105" || "8453":
              chainName = "Base";
              setCurrency("BASE");
              break;
            case "0xa86a" || "43114":
              chainName = "Avalanche C-Chain";
              setCurrency("AVAX");
              break;
            case "0x61" || "97":
              chainName = "BSC Testnet";
              setCurrency("tBNB");
              break;
            case "0xa869" || "43113":
              chainName = "Avalanche Fuji";
              setCurrency("FUJI");
              break;
            case "0x66eee" || "421614":
              chainName = "Arbitrum Sepolia";
              setCurrency("ETH");
              break;
            case "0xfa" || "250":
              chainName = "Fantom Opera";
              setCurrency("FTM");
              break;
            case "0x14a34" || "84532":
              chainName = "Base Sepolia Testnet";
              setCurrency("ETH");
              break;
            default:
              break;
          }

          setCurrentChainName(chainName);
        });
      }

      // Use the chainId as needed in your application
    }, []);
  });

  // const fetchUserBalance = async () => {
  //   if (!window.ethereum) {
  //     return;
  //   }
  //   const web3 = new Web3(window.ethereum);
  //   const accounts = await web3.eth.getAccounts();
  //   const balance = await web3.eth.getBalance(accounts[0]);
  //   setAccountBalance(web3.utils.fromWei(balance, "ether"));
  // };

  const fetchUserBalance = async () => {
    if (!window.ethereum) {
      return;
    }
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    if (accounts.length > 0) {
      const balance = await web3.eth.getBalance(accounts[0]);
      setAccountBalance(web3.utils.fromWei(balance, "ether"));
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      // Fetch balance on component mount
      fetchUserBalance();

      // Add event listener for network changes
      window.ethereum.on("chainChanged", async () => {
        // Reload the page or re-fetch the balance
        await fetchUserBalance();
      });

      // Add event listener for account changes
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          await fetchUserBalance();
        } else {
          setAccountBalance(""); // No account is connected
        }
      });
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", fetchUserBalance);
        window.ethereum.removeListener("accountsChanged", fetchUserBalance);
      }
    };
  }, []);

  const handleHitApiAndDownload = async () => {
    try {
      const solidityCode = generateSolidityCode();
      // console.log("solididytocde", solidityCode);
      const apiData = {
        method: "PUT",
        body: JSON.stringify({
          tokenname: newData?.tokenname,
          filecontentdata: solidityCode,
          walletaddress: newData?.walletaddress,
          id: newData?.id,
        }),
        headers: {
          "Content-type": "application/json",
        },
      };

      const response = await fetch(
        `https://trendifytokens.io/api/files/updatefilecontentdata`,
        apiData
      );
      const responseData = await response.json(); // Convert response to JSON
      // const responseString = JSON.stringify(responseData.status) + ": " + responseData.message; // Concatenate status and message

      // If API call successful, proceed with download
    } catch (error) {
      console.error(error);
    }
  };

  // Making PUT Api CAll for Token And Deploy Status
  const sendDeployStatus = async (address) => {
    try {
      const deployResponse = await axios.put(
        "https://trendifytokens.io/api/files/updatdeploystatus",
        {
          tokenname: tokenName,
          walletaddress: WalletAddress,
          contactaddress: address,
          deploystatus: true,
          chainId: chainID?.toString(),
          id: newData?.id,
        }
      );

      const res = await deployResponse.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeployContract = async () => {
    // Set the Deploy Button Text after clicking on deploy
    setDeployText("DEPLOYING...");
    try {
      const [address, deployStatus] = await deployContract(newData);
      console.log("New Deploy Contract", address, deployStatus);
      setDeploymentError(null);
      if (address && deployStatus) {
        setContractAddress(address);
        handleContractAddressShow(address);
        setDeployText("DEPLOYED");
        setDeployContractBoolean(false);
        sendDeployStatus(address);
        setTimeout(() => {
          navigate("/dashboard");
          resetAll(false);
        }, 10000);
      } else if (deployStatus == false) {
        setDeployText("DEPLOY");
        setDeployContractBoolean(true);
      }
    } catch (error) {
      console.error("Error deploying contract:", error?.code);
      if (error) {
        toast("Transaction Failed", {
          autoClose: 10000,
          style: {
            background:
              "linear-gradient(224deg, rgba(54, 153, 178, 1) 0%, rgba(16, 207, 163, 1) 94.72%)",
          },
          progressClassName: "toast-progress-bar",
        });
      }
      setDeployText("DEPLOY");
      setDeploymentError(error.message);
    } finally {
      if (deployText === "DEPLOYING...") {
        setDeployText("DEPLOY");
      }
    }
  };

  const [showDeployModal, setShowDeployModal] = useState(false);
  const [isDeployBtn, setIsDeployBtn] = useState(false);
  const handleDeployModalClose = (yesBtn) => {
    if (yesBtn === "Yes") {
      setIsDeployBtn(true);
      setShowInnerModal(false);
    } else {
      setShowInnerModal(false);
    }
  };
  const handleDeployModalShow = () => setShowDeployModal(true);
  const handleDeployClick = async () => {
    if (isDeployBtn) {
      handleDeployContract();
    } else {
      handleInnerModalOpen();
    }
  };

  const [paymentHash, setPaymentHash] = useState();
  const [txnStatus, setTxnStatus] = useState(false);

  const sendPaymentStatus = async () => {
    try {
      const response = await axios.put(
        "https://trendifytokens.io/api/files/updatestatus1",
        {
          id: newData?.id,
          transcation: true,
          deploymentstatus: false,
          paymentchainid: chainID?.toString(),
        }
      );
      if (response) {
        // console.log("Sending Payment Status", response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setDownloadFileName = async () => {
    try {
      const response = await axios.put(
        "https://trendifytokens.io/api/files/dashoboardimport",
        {
          tokenname: newData?.tokenname,
          walletaddress: newData?.walletaddress,
          tokenid: newData?.id,
          Ownable2Step: Ownable2StepFile?.replace(".sol", ""),
          ERC20Burnable: ERC20BurnableFile?.replace(".sol", ""),
          ERC20Permit: ERC20PermitFile?.replace(".sol", ""),
          CoinDividendTracker: CoinDividendTrackerFile?.replace(".sol", ""),
          Mintable: MintableFile?.replace(".sol", ""),
          Pausable: PausableFile?.replace(".sol", ""),
          SafeERC20Remastered: SafeERC20RemasteredFile?.replace(".sol", ""),
          IUniswapV2Factory: IUniswapV2FactoryFile?.replace(".sol", ""),
          Initializable: IntializableFile?.replace(".sol", ""),
        }
      );
      if (response) {
        console.log("Post Download File", response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [platformFeeBtn, setplatformFeeBtn] = useState(false);
  const resetPayment = useSelector((state) => state.resetPayment.resetPayment);
  const paymentmatic = async () => {
    setplatformFeeBtn(true);
    const txHashResponse = await transferPayment();
    // console.log("Trancation in Summary", txHashResponse);
    if (txHashResponse?.status) {
      setTxnStatus(true);
      dispatch(setResetPayment(false));
      handleTxhashShow(txHashResponse?.txHash);
      sendPaymentStatus();
      setDownloadFileName();
    }
    setplatformFeeBtn(false);
  };
  useEffect(() => {
    if (resetPayment) {
      setTxnStatus(false);
      setIsDeployBtn(false);
      setDeployText("DEPLOY");
      setDeployContractBoolean(false);
    }
  }, [resetPayment]);

  const getCurrentTxExplorer = async () => {
    const currentChainId = await web3.eth.getChainId();
    // console.log("getCurrentTxExplorerCurrentChainId", currentChainId);
    const currentChainIdInHex = Web3.utils.toHex(currentChainId);
    const networkExplorers = [
      {
        key: 1,
        networkExplorer: "https://etherscan.io/",
        chainId: "0x1",
      },
      {
        key: 2,
        networkExplorer: "https://arbiscan.io/",
        chainId: "0xa4b1",
      },
      {
        key: 3,
        networkExplorer: "https://optimistic.etherscan.io/",
        chainId: "0xa",
      },
      {
        key: 4,
        networkExplorer: "https://polygonscan.com/",
        chainId: "0x89",
      },
      {
        key: 5,
        networkExplorer: "https://bscscan.com/",
        chainId: "0x38",
      },
      {
        key: 6,
        networkExplorer: "https://snowtrace.io/",
        chainId: "0xa86a",
      },
      {
        key: 7,
        networkExplorer: "https://celoscan.io/",
        chainId: "0xa4ec",
      },
      {
        key: 8,
        networkExplorer: "https://basescan.org/",
        chainId: "0x2105",
      },
      {
        key: 9,
        networkExplorer: "https://ftmscan.com/",
        chainId: "0xfa",
      },
      {
        key: 10,
        networkExplorer: "https://testnet.bscscan.com/",
        chainId: "0x61",
      },
      {
        key: 11,
        networkExplorer: "https://sepolia.basescan.org/",
        chainId: "0x14a34",
      },
      {
        key: 12,
        networkExplorer: "https://sepolia.arbiscan.io/",
        chainId: "0x66eee",
      },
      {
        key: 13,
        networkExplorer: "https://testnet.snowtrace.io/",
        chainId: "0xa869",
      },
    ];
    const currentNetworkExplorer = networkExplorers.find(
      (explorer) =>
        explorer.chainId.toLowerCase() === currentChainIdInHex.toLowerCase()
    );

    return currentNetworkExplorer
      ? currentNetworkExplorer.networkExplorer
      : null;
  };
  const handleTxhashShow = async (e) => {
    // const txExplorer = await getCurrentTxExplorer();
    toast(
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="zeroMargin" style={{ color: "#f1f1f1", fontWeight: 600 }}>
          Transaction Receipt
        </p>
        <a href={`${e}`} target="_blank" style={{ color: "#f1f1f1" }}>
          View on Block Explorer
        </a>
      </div>,
      {
        autoClose: 10000,
        style: {
          background:
            "linear-gradient(224deg, rgba(54, 153, 178, 1) 0%, rgba(16, 207, 163, 1) 94.72%)",
        },
        progressClassName: "toast-progress-bar",
      }
    );
  };
  const handleContractAddressShow = async (e) => {
    const txExplorer = await getCurrentTxExplorer();
    toast(
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="zeroMargin" style={{ color: "#f1f1f1", fontWeight: 600 }}>
          Transaction Receipt
        </p>
        <a
          href={`${txExplorer}address/${e}`}
          target="_blank"
          style={{ color: "#f1f1f1" }}
        >
          View on Block Explorer
        </a>
      </div>,
      {
        autoClose: 10000,
        style: {
          background:
            "linear-gradient(224deg, rgba(54, 153, 178, 1) 0%, rgba(16, 207, 163, 1) 94.72%)",
        },
        progressClassName: "toast-progress-bar",
      }
    );
  };
  //

  // Redirect to URL When Wallet Address is Clicked

  const goToURL = async () => {
    let scanUrl;

    const chainId2 = await web3.eth.getChainId();

    let chainIDStr = chainId2.toString();
    switch (chainIDStr) {
      case "1" || "1n" || "0x1": // Polygon Mumbai chain ID
        scanUrl = `https://etherscan.io/token/${contractAddress}`;
        break;
      case "97" || "97n" || "0x61": // Polygon Mainnet chain ID
        scanUrl = `https://testnet.bscscan.com/token/${contractAddress}`;
        break;
      case "137" || "137n" || "0x89": // Polygon Mainnet chain ID
        scanUrl = `https://polygonscan.com/token/${contractAddress}`;
        break;
      case "43114" || "43114n" || "0xa86a": // Avalanche Mainnet chain ID
        scanUrl = `https://43114.snowtrace.io/token/${contractAddress}`;
        break;
      case "10" || "10n" || "0xa": // Opium Mainnet chain ID
        scanUrl = `https://optimistic.etherscan.io/token/${contractAddress}`;
        break;
      case "43113" || "43113n" || "0xa869": // Avalanche Testnet chain ID
        scanUrl = `https://43113.testnet.snowtrace.io/token/${contractAddress}`;
        break;
      case "42161" || "42161n" || "0xa4b1": // Arbitum Testnet chain ID
        scanUrl = `https://arbiscan.io/token/${contractAddress}`;
        break;
      case "56" || "56n" || "0x38": // BSC Testnet chain ID
        scanUrl = `https://bscscan.com/token/${contractAddress}`;
        break;
      case "42220" || "42220n" || "0xa4ec": // Celo Testnet chain ID
        scanUrl = `https://celoscan.io/token/${contractAddress}`;
        break;
      case "8453" || "8453n" || "0x2105": // Base Testnet chain ID
        scanUrl = `https://basescan.org/token/${contractAddress}`;
        break;
      case "250" || "250n" || "0xfa": // Fantom chain ID
        scanUrl = `https://ftmscan.com/token/${contractAddress}`;
        break;
      case "84532" || "84532n" || "0x14a34": // Base Sepolia Testnet chain ID
        scanUrl = `https://sepolia.basescan.org/token/${contractAddress}`;
        break;
      case "421614" || "421614n" || "0x66eee": // Arbitium Sepolia Testnet chain ID
        scanUrl = `https://sepolia.arbiscan.io/token/${contractAddress}`;
        break;
      default:
        // Handle other chain IDs here
        scanUrl = `https://example.com/token/${contractAddress}`; // Default URL
    }
    window.open(scanUrl, "_blank");
  };

  // const showSuccessToast = () => {
  //   toast("Transaction Successful!", {
  //     className: "toast-container",
  //     progressClassName: "toast-progress-bar",
  //   });
  // };

  console.log(
    "Selected Files After Function!!",
    Ownable2StepFile,
    ERC20BurnableFile,
    ERC20PermitFile,
    CoinDividendTrackerFile,
    MintableFile,
    PausableFile,
    SafeERC20RemasteredFile,
    IUniswapV2FactoryFile,
    IntializableFile
  );
  // Function for download file
  const [buttonText, setButtonText] = useState("DOWNLOAD FOR AUDIT");
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isDeployDownloading, setIsDeployDownloading] = useState(false);
  const handleDownload = async () => {
    // if (testNetDownloadDisable) return;
    if (isDeployDownloading) return;
    setIsDeployDownloading(true);

    setButtonText("DOWNLOADING FOR AUDIT....");
    try {
      const response = await fetch(
        `https://trendifytokens.io/api/files/getfile?tokenname=${tokenName}&walletaddress=${WalletAddress}&id=${newData?.id}&ERC20=ERC20&Ownable2Step=${Ownable2StepFile}&ERC20Burnable=${ERC20BurnableFile}&ERC20Permit=${ERC20PermitFile}&CoinDividendTracker=${CoinDividendTrackerFile}&Mintable=${MintableFile}&Pausable=${PausableFile}&SafeERC20Remastered=${SafeERC20RemasteredFile}&IUniswapV2Factory=${IUniswapV2FactoryFile}&Initializable=${IntializableFile}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // Set the filename with .zip extension
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = `${tokenName}.zip`; // Default filename with .zip extension
      if (contentDisposition && contentDisposition.includes("attachment")) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition
        );
        if (matches != null && matches[1]) {
          filename =
            matches[1].replace(/['"]/g, "").replace(/\.[^/.]+$/, "") + ".zip";
        }
      } else {
        filename = `${tokenName}.zip`; // Fallback filename
      }
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsDownloaded(true);
      setButtonText("Downloaded");
    } catch (error) {
      setButtonText("Download Failed");
      console.log(error);
    } finally {
      setIsDeployDownloading(false); // Reset the state variable
    }
  };

  // Resetting All Download File Name
  const resetDownload = useSelector(
    (state) => state.resetDownload.resetDownload
  );
  useEffect(() => {
    if (resetDownload == true || resetDownload == false) {
      setOwnable2StepFile("");
      setERC20BurnableFile("");
      setERC20PermitFile("");
      setCoinDividendTrackerFile("");
      setMintableFile("");
      setPausableFile("");
      setSafeERC20RemasteredFile("");
      setIUniswapV2FactoryFile("");
      setIntializableFile("");
    }
  }, [resetDownload]);

  // State for Disable Download Button for (Testnet)
  // const [testNetDownloadDisable, setTestNetDownloadDisable] = useState(false);
  // console.log("Chain Id in Summary", chainID);

  // useEffect(() => {
  //   const strChainID = chainID.toString();

  //   switch (strChainID) {
  //     case "84532" || "84532n" || "0x14a34":
  //       setTestNetDownloadDisable(true);
  //       break;

  //     case "97" || "97n" || "0x61":
  //       setTestNetDownloadDisable(true);
  //       break;

  //     default:
  //       setTestNetDownloadDisable(false);
  //       break;
  //   }
  // }, [chainID]);

  // Logic for addition of All Taxes
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  useEffect(() => {
    const totalBuyTaxRate =
      (parseFloat(newData?.liquiditybuytaxrateinput / 100) || 0) +
      (parseFloat(newData?.dividendbuytaxrateinput / 100) || 0) +
      (parseFloat(newData?.autoburnbuytaxrateinput / 100) || 0) +
      (parseFloat(newData?.wallettaxes?.[0]?.walletBuyInput / 100) || 0);

    const totalSellTaxRate =
      (parseFloat(newData?.liquidityselltaxrateinput / 100) || 0) +
      (parseFloat(newData?.dividendselltaxrateinput / 100) || 0) +
      (parseFloat(newData?.autoburnselltaxrateinput / 100) || 0) +
      (parseFloat(newData?.wallettaxes?.[0]?.walletSellInput / 100) || 0);

    const totalTransferTaxRate =
      (parseFloat(newData?.liquiditytransfertaxrateinput / 100) || 0) +
      (parseFloat(newData?.dividendtransfertaxrateinput / 100) || 0) +
      (parseFloat(newData?.autoburntransfertaxrateinput / 100) || 0) +
      (parseFloat(newData?.wallettaxes?.[0]?.walletTransferRateInput / 100) ||
        0);

    setIsButtonDisabled(
      totalBuyTaxRate > 25 || totalSellTaxRate > 25 || totalTransferTaxRate > 25
    );
  }, [newData]);

  // console.log("Contract Boolean" , deployContractBoolean);

  return (
    <>
      <div className="container py-4" style={{ backgroundColor: "#17171B" }}>
        <div className="my-4 summary-table-heading-color">
          <SingleSummaryRow
            Label={
              newData ? `${newData?.tokenname} (${newData?.tokensymbol})` : "-"
            }
            Value={currency}
            firstHeader="true"
          />
        </div>
        <SingleSummaryRow Label="General" heading="true" />
        <SingleSummaryRow Label="Token Name" Value={tokenName} />
        <SingleSummaryRow Label="Token Symbol" Value={newData?.tokensymbol} />
        <SingleSummaryRow Label="Decimal" Value={newData?.decimals} />
        <SingleSummaryRow
          Label="Initial Supply"
          Value={formatInteger(newData?.initialsupply / 10)}
        />
        {newData?.mintable && (
          <SingleSummaryRow
            Label="Mintable"
            Value={formatInteger(newData?.mintableinput / 10)}
          />
        )}
        {newData?.supplyrecipitent && (
          <SingleSummaryRow
            Label="Supply recipient"
            Value={truncateText(newData?.supplyrecipitentinput)}
          />
        )}
        {newData?.tokenowner && (
          <SingleSummaryRow
            Label="Owner"
            Value={truncateText(newData?.tokenownerinput)}
          />
        )}

        {/* Optional Section  Starts Here */}
        {newData?.defaultexchange ||
        newData?.maxamountperwallet ||
        newData?.maxtransactionlimit ||
        newData?.pausable ||
        newData?.blacklist ||
        newData?.foregintokenrecovery ||
        newData?.permit ? (
          <SingleSummaryRow Label="Optional" heading="true" />
        ) : null}

        {newData?.defaultexchange && (
          <SingleSummaryRow
            Label="DEX"
            Value={newData?.defaultexchangeselect}
          />
        )}

        {newData?.defaultexchange && newData?.antibotcooldown && (
          <SingleSummaryRow
            Label="Anti-bot cooldown"
            Value={convertCooldownTime(
              newData?.antibotcooldowninput,
              antibotCoolDownTime
            )}
          />
        )}

        {newData?.maxamountperwallet && (
          <SingleSummaryRow
            Label="Max wallet amount"
            Value={`${formatInteger(newData?.maxamountperwalletinput / 10)} ${
              newData?.tokensymbol
            }`}
          />
        )}

        {newData?.defaultexchange == true &&
          newData?.maxtransactionlimit === true && (
            <SingleSummaryRow
              Label="Max transaction limits"
              subHeading="true"
            />
          )}

        {/* If Default Exchange is false and max transaction is true */}
        {newData?.defaultexchange === false &&
        newData?.maxtransactionlimit === true ? (
          <>
            <SingleSummaryRow
              Label="Max transaction limits"
              subHeading="true"
            />
            <TaxSummaryRow
              Label="Max transaction limit"
              value={`${formatInteger(
                newData?.maxtransactionlimitinput / 10
              )} ${newData?.tokensymbol}`}
            />
          </>
        ) : null}

        {newData?.enbletradingfunction && (
          <SingleSummaryRow
            Label="Enable Trading function"
            Value={newData?.enbletradingfunction ? "Enabled" : "Disabled"}
          />
        )}

        {newData?.defaultexchange == true &&
          newData?.maxtransactionlimit === true &&
          newData?.onbuyingcheck && (
            <SingleSummaryRow
              Label="Max buy limit"
              Value={`${formatInteger(newData?.onbuyingcheckinput / 10)} ${
                newData?.tokensymbol
              }`}
              nested="true"
            />
          )}

        {newData?.defaultexchange &&
          newData?.maxtransactionlimit &&
          newData?.onsellingcheck && (
            <SingleSummaryRow
              Label="Max sell limit"
              Value={`${formatInteger(newData?.onsellingcheckinput / 10)} ${
                newData?.tokensymbol
              }`}
              nested="true"
            />
          )}

        {newData?.defaultexchange &&
          newData?.maxtransactionlimit &&
          newData?.ontransferringcheck && (
            <SingleSummaryRow
              Label="Max transfer limit"
              Value={`${formatInteger(
                newData?.ontransferringcheckinput / 10
              )} ${newData?.tokensymbol}`}
              nested="true"
            />
          )}

        {newData?.pausable && (
          <SingleSummaryRow
            Label="Pausable"
            Value={newData?.pausable ? "Enabled" : "Disabled"}
          />
        )}

        {newData?.blacklist && (
          <SingleSummaryRow
            Label="Blacklist"
            Value={newData?.blacklist ? "Enabled" : "Disabled"}
          />
        )}

        {newData?.foregintokenrecovery && (
          <SingleSummaryRow
            Label="Foreign Token Recovery"
            Value={newData?.foregintokenrecovery ? "Enabled" : "Disabled"}
          />
        )}

        {newData?.permit && (
          <SingleSummaryRow
            Label="Permit"
            Value={newData?.permit ? "Enabled" : "Disabled"}
          />
        )}

        {/* Taxes Section Starts Here */}
        {(newData?.taxesdefaultexchange && newData?.taxesliquiditytax) ||
        newData?.dividendtax ||
        newData?.autoburtx ||
        newData?.wallettaxes?.length > 0 ||
        (!newData?.taxesdefaultexchange && newData?.wallettaxes?.length > 0) ? (
          <SingleSummaryRow Label="Taxes" heading="true" />
        ) : null}

        {newData?.taxesdefaultexchange && newData?.taxesliquiditytax && (
          <TaxSummaryRow Label="Liquidity tax" subHeading={true} />
        )}

        {newData?.taxesliquiditytax && (
          <>
            <TaxSummaryRow
              nested={true}
              taxRate={true}
              buyValue={
                (newData?.liquiditybuytaxrateinput / 100).toFixed(2) || "0"
              }
              sellValue={
                (newData?.liquidityselltaxrateinput / 100).toFixed(2) || "0"
              }
              transferValue={
                (newData?.liquiditytransfertaxrateinput / 100).toFixed(2) || "0"
              }
            />
            <TaxSummaryRow nested={true} info="LP will be added in main liquidity pool" />
          </>
        )}

        {newData?.dividendtax && (
          <>
            <TaxSummaryRow
              nested={true}
              taxRate={true}
              buyValue={
                (newData?.dividendbuytaxrateinput / 100).toFixed(2) || "0"
              }
              sellValue={
                (newData?.dividendselltaxrateinput / 100).toFixed(2) || "0"
              }
              transferValue={
                (newData?.dividendtransfertaxrateinput / 100).toFixed(2) || "0"
              }
            />
            <TaxSummaryRow
              nested={true}
              info={`auto-claim every ${newData?.autoclaiminterval} ${newData?.autoclaimintervaltime} using ${newData?.gasautoclaim} gas units`}
            />
          </>
        )}

        {newData?.autoburtx && (
          <TaxSummaryRow Label="Auto-burn tax" subHeading={true} />
        )}

        {newData?.taxesdefaultexchange && newData?.autoburtx && (
          <TaxSummaryRow
            nested={true}
            taxRate={true}
            buyValue={
              (newData?.autoburnbuytaxrateinput / 100).toFixed(2) || "0"
            }
            sellValue={
              (newData?.autoburnselltaxrateinput / 100).toFixed(2) || "0"
            }
            transferValue={
              (newData?.autoburntransfertaxrateinput / 100).toFixed(2) || "0"
            }
          />
        )}

        {newData?.taxesdefaultexchange == true &&
          newData?.wallettaxes?.length > 0 && (
            <>
              <TaxSummaryRow
                Label={`${newData?.wallettaxes[0]?.walletTaxname} Tax`}
                subHeading={true}
              />
              <TaxSummaryRow
                nested={true}
                taxRate={true}
                buyValue={
                  (newData?.wallettaxes[0]?.walletBuyInput / 100).toFixed(2) ||
                  "0"
                }
                sellValue={
                  (newData?.wallettaxes[0]?.walletSellInput / 100).toFixed(2) ||
                  "0"
                }
                transferValue={
                  (
                    newData?.wallettaxes[0]?.walletTransferRateInput / 100
                  ).toFixed(2) || "0"
                }
              />
              <TaxSummaryRow
                nested={true}
                info={`send to: ${truncateText(
                  newData?.wallettaxes[0]?.walletRecipientAddress
                )}`}
              />
            </>
          )}

        {/* Show Only when Auto Burn tax is enabled and default exchange is false */}
        {newData?.taxesdefaultexchange == false &&
        newData?.autoburtx == true ? (
          <>
            <TaxSummaryRow
              autoBurnTaxInputValue={parseFloat(
                newData?.autoBurnTaxInput / 100
              ).toFixed(2)}
            />
            {newData?.wallettaxes?.length > 0 && (
              <>
                <TaxSummaryRow
                  Label={`${newData?.wallettaxes[0]?.walletTaxname} Tax`}
                  subHeading={true}
                />
                <TaxSummaryRow
                  nested={true}
                  info={`tax rate: ${
                    parseFloat(
                      newData?.wallettaxes[0]?.walletTaxRateInput / 100
                    ).toFixed(2) || 0
                  }%`}
                />
                <TaxSummaryRow
                  nested={true}
                  info={`send to: ${truncateText(
                    newData?.wallettaxes[0]?.walletRecipientAddress
                  )}`}
                />
              </>
            )}
            <SingleSummaryRow Label="Total tax rates" heading="true" />
            <TaxSummaryRow
              Label="All transaction types"
              value={
                (
                  parseFloat(newData?.autoBurnTaxInput / 100) +
                  parseFloat(
                    newData?.wallettaxes[0]?.walletTaxRateInput / 100 || 0
                  )
                ).toFixed(2) + "%"
              }
            />
          </>
        ) : null}

        {newData?.taxesdefaultexchange &&
          (newData?.taxesliquiditytax || newData?.dividendtax) && (
            <TaxSummaryRow
              Label="Swap threshold ratio"
              subHeading={true}
              value={`${
                newData?.swapthresholdratio / 100 || "0"
              }% of liquidity`}
            />
          )}

        {newData?.taxesdefaultexchange &&
        (newData?.taxesliquiditytax ||
          newData?.dividendtax ||
          newData?.autoburtx ||
          newData?.wallettaxes?.length > 0 ||
          (newData?.taxesliquiditytax &&
            newData?.dividendtax &&
            newData?.autoburtx &&
            newData?.wallettaxes?.length > 0)) ? (
          <>
            <SingleSummaryRow Label="Total tax rates" heading="true" />

            <TaxSummaryRow
              Label="For buying"
              value={
                (
                  (parseFloat(newData?.liquiditybuytaxrateinput / 100) || 0) +
                  (parseFloat(newData?.dividendbuytaxrateinput / 100) || 0) +
                  (parseFloat(newData?.autoburnbuytaxrateinput / 100) || 0) +
                  (parseFloat(newData?.wallettaxes[0]?.walletBuyInput / 100) ||
                    0)
                ).toFixed(2) + "%"
              }
            />

            <TaxSummaryRow
              Label="For selling"
              value={
                (
                  (parseFloat(newData?.liquidityselltaxrateinput / 100) || 0) +
                  (parseFloat(newData?.dividendselltaxrateinput / 100) || 0) +
                  (parseFloat(newData?.autoburnselltaxrateinput / 100) || 0) +
                  (parseFloat(newData?.wallettaxes[0]?.walletSellInput / 100) ||
                    0)
                ).toFixed(2) + "%"
              }
            />

            <TaxSummaryRow
              Label="For transferring"
              value={
                (
                  (parseFloat(newData?.liquiditytransfertaxrateinput / 100) ||
                    0) +
                  (parseFloat(newData?.dividendtransfertaxrateinput / 100) ||
                    0) +
                  (parseFloat(newData?.autoburntransfertaxrateinput / 100) ||
                    0) +
                  (parseFloat(
                    newData?.wallettaxes[0]?.walletTransferRateInput / 100
                  ) || 0)
                ).toFixed(2) + "%"
              }
            />
          </>
        ) : null}

        {/* Single Wallet Taxes */}
        {newData?.taxesdefaultexchange == false &&
          newData?.wallettaxes?.length > 0 &&
          newData?.autoburtx == false && (
            <>
              <TaxSummaryRow
                Label={`${newData?.wallettaxes[0]?.walletTaxname} Tax`}
                subHeading={true}
              />
              <TaxSummaryRow
                nested={true}
                info={`tax rate: ${
                  parseFloat(
                    newData?.wallettaxes[0]?.walletTaxRateInput / 100
                  ).toFixed(2) || 0
                } %`}
              />
              <TaxSummaryRow
                nested={true}
                info={`send to: ${truncateText(
                  newData?.wallettaxes[0]?.walletRecipientAddress
                )}`}
              />
              <SingleSummaryRow Label="Total tax rates" heading="true" />
              <TaxSummaryRow
                Label="All transaction types"
                value={`${
                  parseFloat(
                    newData?.wallettaxes[0]?.walletTaxRateInput / 100
                  ).toFixed(2) || 0
                } %`}
              />
            </>
          )}
        <div
          style={{
            border: `${isCheckboxChecked ? "1px solid #E5B649" : ""}`,
            padding: "0.5rem 1rem",
            width: "98%",
            margin: "1rem auto",
            borderRadius: "4px",
          }}
        >
          {/* <Form.Check
            className="text-white my-4"
            type="checkbox"
            id="checkbox"
            onChange={handleCheckboxChange}
            label="I understand that some values are unchangeable after token creation. Full list available here."
          /> */}

          <div className="checkbox-container flex-column flex-md-row align-items-start align-items-md-center">
            <input
              type="checkbox"
              id={"check"}
              name={"check"}
              onChange={handleCheckboxChange}
              checked={isCheckboxChecked}
            />
            <label htmlFor={"check"} style={{ color: "#E5B649" }}>
              I understand that some values are unchangeable after token
              creation.
              {/* <span style={{ display: "block", color: "#42E8E0" }}>
                *After payment, clicking the previous button will generate a new
                token, requiring another payment.
              </span> */}
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Col md={3} className="text-center mx-auto">
            <button
              onClick={() => handlePrevious()}
              className="w-100 border-color"
              style={{ display: txnStatus ? "none" : "block" }}
              type="button"
            >
              Previous
            </button>
          </Col>
          <Col md={3} className="text-center mx-auto">
            <Button
              onClick={() => {
                compile();
              }}
              style={{
                background: "linear-gradient(rgb(19 107 127), rgb(6 141 114))",
                border: "0px",
                width: "100%",
                // maxWidth: "310px",
              }}
              disabled={!isCheckboxChecked || isButtonDisabled}
            >
              Validate
            </Button>
          </Col>
        </div>

        {isButtonDisabled && (
          <div
            className="d-flex align-items-center gap-3 "
            style={{
              backgroundColor: "#7F1D1D",
              padding: "1rem",
              borderRadius: "0.5rem",
              color: "white",
              marginTop: "1rem",
            }}
          >
            <IoWarningOutline size="1.5rem" />
            <p>
              Total tax rates are too high. Please make sure each type does not
              exceed 25%.
            </p>
          </div>
        )}

        {/* //////// New Modal */}

        {showOuterModal && (
          <div className="modal-overlay" onClick={handleOuterModalClose}>
            <div className="outer-modal" onClick={(e) => e.stopPropagation()}>
              <div className="d-flex align-items-start justify-content-between">
                {/* <h2 className="modal-header">Create {tokenSymbol} Token</h2> */}
                <div
                  style={{
                    color: "#f1f1f1",
                    fontWeight: 700,
                    fontSize: "18px",
                    margin: "0 0 0.5rem 0",
                  }}
                  className={`d-flex align-items-start gap-2 ${
                    tokenSymbol?.length > 16 ? "flex-column" : "flex-row"
                  } flex-md-row`}
                >
                  <p>Create</p>
                  <p>{tokenSymbol} Token</p>
                </div>
                <img
                  src={CrossIcon}
                  alt="cancelBtn"
                  style={{ cursor: "pointer" }}
                  onClick={handleOuterModalClose}
                />
              </div>
              <div className="separator-line"></div>
              {/* <p className="para">Your token address after deployment:</p> */}
              {deployContractBoolean == true && (
                <>
                  <div className="modal-description d-flex align-items-center justify-content-center gap-4 my-3">
                    <p className="wallet-address zeroMargin">
                      Something Went Wrong
                    </p>
                  </div>
                </>
              )}
              <div className="d-flex flex-column gap-3">
                {contractAddress && (
                  <>
                    <div className="modal-description d-flex align-items-center justify-content-center gap-4">
                      <p
                        className="wallet-address zeroMargin"
                        onClick={goToURL}
                        style={{ wordBreak: "break-word" }}
                      >
                        {contractAddress}
                      </p>
                      {contractAddress && (
                        <>
                          <CopyToClipboard
                            text={contractAddress}
                            onCopy={onCopyText}
                            className="border-0"
                          >
                            <img
                              src={CopyIcon}
                              alt="Copy_Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </CopyToClipboard>
                        </>
                      )}
                    </div>
                  </>
                )}
                {/* <div>
                  <Form.Select
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      border: "0",
                    }}
                    className="mt-2 white-color no-outline"
                    onChange={handleSelectChange}
                    value={selectedOption}
                  >
                    {Options.map((option, index) => (
                      <option
                        key={index}
                        value={option}
                        className="bg-dark my-3"
                      >
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                </div> */}
                {selectedOption === "Modify by salt" && (
                  <>
                    <div>
                      <h6 className="my-2 white-color">Seed (salt)</h6>
                      <div className="separator-line mb-0"></div>
                      <p
                        className="zeroMargin"
                        style={{
                          color: "#D7D7D7",
                          padding: "0.5rem 0",
                          fontWeight: 500,
                        }}
                      >
                        Change the seed(salt) in the deployment transaction.
                        This allows you to easily modify the address where your
                        token will be deployed.
                      </p>
                      <div className="seed-container">
                        <button onClick={handleDecrement}>
                          <img src={MinusIcon} alt="MinusIcon" />
                        </button>
                        <div className="w-50 mx-auto text-center">
                          <p className="zeroMargin white-color">
                            {saltValue.toFixed(1)}
                          </p>
                        </div>
                        <button onClick={handleIncrement}>
                          <img src={PlusIcon} alt="plusIcon" />
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div className="modal-description">
                  {txnStatus && !resetPayment ? (
                    <>
                      <div className="my-2 p-2 text-center">
                        <p style={{ color: "#F1F1F1" }} className="mb-2">
                          {/* Platform Payment Successful : {amount} {currency} */}
                          Platform Payment Successful : 100 USDC
                        </p>
                        <div className="d-flex flex-column flex-md-row align-items-center gap-3">
                          {!isDownloaded && !isDeployBtn && (
                            <button
                              className="download-btn"
                              onClick={handleDownload}
                              style={{
                                background: `${
                                  buttonText === "DOWNLOADING FOR AUDIT"
                                    ? "#17171B"
                                    : ""
                                }`,
                                opacity: `${
                                  buttonText === "DOWNLOADING FOR AUDIT"
                                    ? "0.5"
                                    : "1"
                                }`,
                              }}
                            >
                              {buttonText}
                            </button>
                          )}

                          <button
                            style={{
                              width: `${
                                isDownloaded == true || isDeployBtn == true
                                  ? // testNetDownloadDisable == true
                                    "100%"
                                  : "50%"
                              }`,
                              background: `${
                                deployText === "DEPLOYING..." ? "#17171B" : ""
                              }`,
                              opacity: `${
                                deployText === "DEPLOYING..." ? "0.5" : "1"
                              }`,
                            }}
                            className="deploy-btn d-flex align-items-center justify-content-center gap-1"
                            onClick={() => {
                              if (!isDeployDownloading && !isDeployBtn) {
                                handleDownload();
                              }
                              handleDeployClick();
                            }}
                            disabled={deployText == "DEPLOYED"}
                          >
                            {deployText}
                            {deployText === "DEPLOYED" && (
                              <img
                                src={CheckSuccess}
                                alt="check_icon"
                                style={{
                                  width: "1.2rem",
                                  marginBottom: "0.2rem",
                                }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <div className="text-center">
                        <p className="my-1">
                          <span
                            style={{ color: "#D7D7D7", marginRight: "0.3rem" }}
                          >
                            Your balance :
                          </span>
                          <span style={{ color: "#F1F1F1" }}>
                            {accountBalance} {currency}
                          </span>
                        </p>
                        <p className="my-1">
                          <span
                            style={{ color: "#D7D7D7", marginRight: "0.3rem" }}
                          >
                            Trend Smart Contract fee :
                          </span>
                          <span style={{ color: "#F1F1F1" }}>
                            {/* {amount} {currency} */}
                            100 USDC
                          </span>
                        </p>
                        {/* <p className="my-1">
                          <span
                            style={{ color: "#D7D7D7", marginRight: "0.3rem" }}
                          >
                            Estimated gas fee :
                          </span>
                          <span style={{ color: "#F1F1F1" }}>
                            {estimatedGasFee} {currency}
                          </span>
                        </p> */}
                      </div>

                      <button
                        onClick={paymentmatic}
                        style={{
                          color: "#0AE7FE",
                          opacity: platformFeeBtn ? "0.5" : "1",
                        }}
                        className="payment-btn"
                      >
                        PAY PLATFORM FEE
                      </button>
                      {/* {contractAddress && <Button>DOWNLOAD</Button>} */}
                    </div>
                  )}
                </div>
              </div>

              {showInnerModal && (
                <div className="inner-modal-overlay">
                  <div className="inner-modal">
                    <h3 className="deploy-modal-header">
                      Are you sure you want to Deploy?
                    </h3>
                    <p className="deploy-description">
                      *Note: You can download it from Explorer once the contract
                      has been verified.
                    </p>
                    <div className="flex">
                      <button
                        className="no-btn button"
                        onClick={handleDeployModalClose}
                      >
                        No
                      </button>
                      <button
                        className="yes-btn button"
                        onClick={() => handleDeployModalClose("Yes")}
                      >
                        Yes
                      </button>
                    </div>
                    <p
                      className="text-center my-1"
                      style={{ fontWeight: 600, color: "#0AE7FE" }}
                    >
                      Once the Contract is Deployed the code cannot be changed.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <ToastContainer autoClose={10000} />
      </div>
    </>
  );
}
export default Summary;

import React from "react";
import InputGroup from "./InputGroup";
import PermitInputGroup from "./PermitInputGroup/PermitInputGroup";

const Permit = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Permit</p>
      <InputGroup
        Label="Permit signing tool"
        subHeading={
          "Use your currently connected wallet to sign a permit message and obtain V, R & S values."
        }
        ButtonLabel="Open signing tool"
      />
      <PermitInputGroup
        Label="Permit"
        subHeading="permit( owner, spender, value, deadline, v, r, s ) - Use signed message  by “Owner” to approve tokens to be spend by “Spender”. This function is here for demonstration purposes. Other platforms should automatically detect Permit function later and use it to optimize approving tokens."
        InputLabel1="Owner"
        InputPlaceHolder1="0x......."
        InputLabel2="Spender"
        InputPlaceHolder2="0x......."
        InputLabel3="Value"
        InputPlaceHolder3="123,456"
        InputLabel4="Deadline"
        InputPlaceHolder4="1712126770"
        InputLabel5="V"
        InputPlaceHolder5="123..."
        InputLabel6="R"
        InputPlaceHolder6="123..."
        InputLabel7="S"
        InputPlaceHolder7="123..."
        ButtonLabel="Permit"
        margin="my-0"
      />
    </div>
  );
};

export default Permit;

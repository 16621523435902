import React from "react";

const TaxSummaryRow = ({
  Label,
  subHeading,
  value,
  nested,
  buyValue = "0",
  sellValue = "0",
  transferValue = "0",
  taxRate,
  autoBurnTaxInputValue,
  autoClaim,
  info,
}) => {
  return (
    <tr
      className={`d-flex justify-content-between px-2 py-2 `}
      style={{
        border: "1px solid #2D2D2D",
      }}
    >
      <td
        style={{
          fontWeight: subHeading ? "bold" : "normal",
          color: subHeading ? "#9B9797" : "#9B9797",
        }}
      >
        <div className="d-flex align-items-center gap-2">
          {nested && (
            <div
              style={{
                width: "5px",
                height: "5px",
                backgroundColor: "#9B9797",
                borderRadius: "50%",
              }}
            ></div>
          )}
          {taxRate ? (
            <p style={{ color: "#9B9797" }}>
              tax rates: {buyValue}%, {sellValue}%, {transferValue}%
            </p>
          ) : (
            <p style={{ color: "#9B9797" }}>{info}</p>
          )}
          {autoBurnTaxInputValue && (
            <p style={{ color: "#9B9797" }}>
              tax rates: {autoBurnTaxInputValue}%
            </p>
          )}
        </div>
        {Label}
      </td>
      <td style={{ color: "#F1F1F1" }}>{value}</td>
    </tr>
  );
};

export default TaxSummaryRow;

import InputGroup from "./InputGroup";
import InputHeader from "./InputHeader";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";
import CopyIcon from "../../Images/Logos/copy.svg";

const Mintable = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Mintable</p>
      <InputHeader
        label="Max Supply"
        subHeading="maxSupply() - Shows the max cap which a total supply can reach. Total supply cannot be greater than max supply."
      />

      <p style={{ color: "#0AE7FE", wordBreak: "break-word" }}>90 000 000 AT</p>

      <OwnerOnlyWrapper>
        <InputGroup
          Label="Mint"
          subHeading={
            "mint( to, amount ) - Create new tokens and on To address"
          }
          InputLabel1="To"
          InputPlaceHolder1="0x......."
          InputLabel2="Amount"
          InputPlaceHolder2="123,456"
          ButtonLabel="Mint"
          margin="my-0"
        />
      </OwnerOnlyWrapper>
    </div>
  );
};

export default Mintable;

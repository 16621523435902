import React from "react";
import Divider from "./divider";
import { Col, FormLabel, Row, Form } from "react-bootstrap";

const InputGroup = ({
  Label,
  subHeading,
  InputLabel1,
  InputLabel2,
  InputPlaceHolder1,
  InputPlaceHolder2,
  ButtonLabel,
  OptionLabel,
  Options,
  ButtonColor = "linear-gradient(224deg, rgba(54, 153, 178, 0.50) 0%, rgba(16, 207, 163, 0.50) 94.72%)",
  margin = "my-4",
}) => {
  return (
    <div className={`${margin}`}>
      <p className="gray-text">{Label}</p>
      <Divider />
      <p className="gray-text mb-2">{subHeading}</p>
      <Row>
        {InputLabel1 && (
          <Col sm={12} md={InputLabel2 || Options ? 6 : 12}>
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel1}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder1}
            />
          </Col>
        )}

        {InputLabel2 && (
          <Col sm={12} md={6}>
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel2}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder2}
            />
          </Col>
        )}

        {Options && (
          <Col sm={12} md={6}>
            <div className="mt-3 mt-md-0">
              {OptionLabel && (
                <label style={{ color: "#F1F1F1" }}>{OptionLabel}</label>
              )}
              <Form.Select
                style={{
                  color: "#F1F1F1",
                  borderColor: "#3A3A3A",
                  marginTop: "0.5rem",
                }}
                className="bg-transparent placeholderColor"
              >
                {Options?.map((option, index) => (
                  <option key={index} value={option} className="bg-dark">
                    {option}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
        )}
      </Row>
      <button
        className="px-3 py-2 rounded-2 text-white mt-3 dashboard-btn"
        style={{
          background: ButtonColor,
          border: "none",
        }}
      >
        {ButtonLabel}
      </button>
    </div>
  );
};

export default InputGroup;

import { Outlet } from "react-router";
import NavigationBar from "./Components/NavigationBar";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const MainApp = () => {
  return (
    <div>
      <NavigationBar />
      <Outlet />
    </div>
  );
};

export default MainApp;

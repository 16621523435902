import React, { useEffect, useState } from "react";
import BackArrow from "../Images/Logos/back.svg";
import { useNavigate, useParams } from "react-router";
import TokenHeaderInfo from "../Components/DashBoard/TokenHeaderInfo/TokenHeaderInfo";
import LiquidityPoolInfo from "../Components/DashBoard/TokenHeaderInfo/LiquidityPoolInfo";
import General from "../Components/DashBoard/General";
import Ownable from "../Components/DashBoard/Ownable";
import Mintable from "../Components/DashBoard/Mintable";
import AntiBotCooldown from "../Components/DashBoard/AntiBotCooldown";
import GeneralLogo from "../Images/Logos/General.svg";
import OwnableLogo from "../Images/Logos/Ownable.svg";
import MintaleLogo from "../Images/Logos/Mintable.svg";
import AntiBootCoolDownLogo from "../Images/Logos/antibootcooldown.svg";
import EnableTradingFunctionLogo from "../Images/Logos/enabletradingfunction.svg";
import MaxAmountPerWalletLogo from "../Images/Logos/maxamountperwallet.svg";
import MaxTransactionLimitLogo from "../Images/Logos/maxtransactionlimits.svg";
import PausableLogo from "../Images/Logos/Pausable.svg";
import BlacklistLogo from "../Images/Logos/Blacklist.svg";
import ForeignTokenRecoveryLogo from "../Images/Logos/foreigntokenrecovery.svg";
import PermitLogo from "../Images/Logos/Permit.svg";
import { HiMiniChevronLeft } from "react-icons/hi2";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import EnableTradingFunction from "../Components/DashBoard/EnableTradingFunction";
import MaxAmountPerWallet from "../Components/DashBoard/MaxAmountPerWallet";
import MaxTransactionLimit from "../Components/DashBoard/MaxTransactionLimit";
import Pausable from "../Components/DashBoard/Pausable";
import Blacklist from "../Components/DashBoard/Blacklist";
import ForeignTokenRecovery from "../Components/DashBoard/ForeignTokenRecovery";
import Permit from "../Components/DashBoard/Permit";

const Manage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("General");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tokenDetail, setTokenDetail] = useState();

  const { id } = useParams();
  console.log(id);
  const renderComponent = () => {
    switch (activeTab) {
      case "General":
        return <General />;
      case "Ownable":
        return <Ownable />;
      case "Mintable":
        return <Mintable />;
      case "Antibotcooldown":
        return <AntiBotCooldown />;
      case "EnableTradingFunction":
        return <EnableTradingFunction />;
      case "MaxAmountPerWallet":
        return <MaxAmountPerWallet />;
      case "MaxTransactionLimit":
        return <MaxTransactionLimit />;
      case "Pausable":
        return <Pausable />;
      case "Blacklist":
        return <Blacklist />;
      case "ForeignTokenRecovery":
        return <ForeignTokenRecovery />;
      case "Permit":
        return <Permit />;
      default:
        return <General />;
    }
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setIsSidebarOpen(false);
  };

  // Fetching data for particular Token Address
  const getTokenInfo = async () => {
    try {
      const responseData = await axios.get(
        `https://trendifytokens.io/api/files/getdatacontacadress?contactaddress=${id}`
      );
      const jsonData = await responseData.data;
      if (jsonData?.status == true) {
        console.log("Response in Manage", jsonData);
        setTokenDetail(jsonData?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTokenInfo();
  }, []);

  console.log("Manage Token Details", tokenDetail);
  return (
    <section style={{ width: "90%", margin: "auto" }}>
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="flex align-items-center gap-2 bg-transparent border-0"
      >
        <img src={BackArrow} alt="backArrow" />
        <p className="zeroMargin text-light">Back</p>
      </button>
      {/* Token Info Header */}
      <TokenHeaderInfo data={tokenDetail} />
      {/* Liquidity Pool Info */}
      {/* <LiquidityPoolInfo /> */}

      <div
        className="menu-icon d-flex align-items-center d-md-none"
        onClick={toggleSidebar}
      >
        <HiMiniChevronLeft className="text-white " size="2rem" />
        <p className="text-white">Menu</p>
      </div>
      <div style={{ display: "flex", margin: "1rem 0" }}>
        {/* Sidebar */}
        <div
          className={`sidebar dashboard-primary-bg p-3 rounded-3 ${
            isSidebarOpen ? "open" : ""
          }`}
          style={{ width: "22rem" }}
        >
          <IoCloseOutline
            onClick={toggleSidebar}
            className="text-white mb-3 mb-md-0 d-md-none"
            size="2rem"
          />
          <p className="text-white mb-3">Features</p>
          <div>
            <input
              type="text"
              placeholder="Search"
              className="px-3 py-2 mb-3 w-100 searchinput"
            />
          </div>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "General" ? "rgba(255, 255, 255, 0.30)" : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("General")}
            >
              <img src={GeneralLogo} alt="generalLogo" />
              <p>General (ERC - 20)</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "Ownable" ? "rgba(255, 255, 255, 0.30)" : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("Ownable")}
            >
              <img src={OwnableLogo} alt="OwnableLogo" />
              <p>Ownable</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "Mintable" ? "rgba(255, 255, 255, 0.30)" : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("Mintable")}
            >
              <img src={MintaleLogo} alt="OwnableLogo" />
              <p>Mintable</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "Antibotcooldown"
                    ? "rgba(255, 255, 255, 0.30)"
                    : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("Antibotcooldown")}
            >
              <img src={AntiBootCoolDownLogo} alt="antibootcooldown" />
              <p>Anti-bot cooldown</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "EnableTradingFunction"
                    ? "rgba(255, 255, 255, 0.30)"
                    : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("EnableTradingFunction")}
            >
              <img
                src={EnableTradingFunctionLogo}
                alt="EnableTradingFunction"
              />
              <p>EnableTradingFunction</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "MaxAmountPerWallet"
                    ? "rgba(255, 255, 255, 0.30)"
                    : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("MaxAmountPerWallet")}
            >
              <img src={MaxAmountPerWalletLogo} alt="MaxAmountPerWallet" />
              <p>Max amount per wallet</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "MaxTransactionLimit"
                    ? "rgba(255, 255, 255, 0.30)"
                    : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("MaxTransactionLimit")}
            >
              <img src={MaxTransactionLimitLogo} alt="MaxTransactionLimit" />
              <p>Max transaction limits</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "Pausable" ? "rgba(255, 255, 255, 0.30)" : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("Pausable")}
            >
              <img src={PausableLogo} alt="Pausable" />
              <p>Pausable</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "Blacklist" ? "rgba(255, 255, 255, 0.30)" : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("Blacklist")}
            >
              <img src={BlacklistLogo} alt="Blacklist" />
              <p>Blacklist</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "ForeignTokenRecovery"
                    ? "rgba(255, 255, 255, 0.30)"
                    : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("ForeignTokenRecovery")}
            >
              <img src={ForeignTokenRecoveryLogo} alt="ForeignTokenRecovery" />
              <p>Foreign token recovery</p>
            </li>
            <li
              className="px-3 py-2 d-flex gap-2 align-items-center text-white rounded-2"
              style={{
                background:
                  activeTab == "Permit" ? "rgba(255, 255, 255, 0.30)" : "",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("Permit")}
            >
              <img src={PermitLogo} alt="Permit" />
              <p>Permit</p>
            </li>
          </ul>
        </div>

        {/* Main Content */}
        <div className="dashboardContainer ms-md-3">{renderComponent()}</div>
      </div>
    </section>
  );
};

export default Manage;

import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import "./Switch.css";

function Switch({ Label, Name, Description, checked, onChange }) {
  return (
    <Col>
      {/* <Form.Check
        className="mt-3 custom-switch"
        style={{ color: "#F1F1F1" }}
        type="switch"
        name={Name}
        id={Label}
        checked={checked}
        onChange={onChange}
        label={Label}
      />
      <div style={{ color: "#737373" }} className="mt-3">
        {Description}
      </div> */}

      <div className="toggle-switch-container">
        <label className="switch">
          <input
            type="checkbox"
            name={Name}
            id={Label}
            checked={checked}
            onChange={onChange}
          />
          <span className="slider" />
        </label>
        <label htmlFor={Label} style={{ color: "#F1F1F1" }}>
          {Label}
        </label>
      </div>
      <div style={{ color: "#737373" }} className="mt-1">
        {Description}
      </div>
    </Col>
  );
}

export default Switch;

import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import logo from "../Images/Home/logo.png";
import network from "../Images/Logos/NETWORK.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./NavigationBar.css";
import { updateWalletAddress } from "../../src/Store/Slices/GeneralSlice";
import { useDispatch } from "react-redux";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider, useConfig, useAccount } from "wagmi";
import {
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  bscTestnet,
  baseSepolia,
  fantom
} from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { HiBars3, HiXMark } from "react-icons/hi2";
// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "58a22d2bc1c793fc31c117ad9ceba8d9";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  fantom,
  bscTestnet,
  baseSepolia
];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,

  // ...wagmiOptions // Optional - Override createConfig parameters
});

// const connectedAddress = window.ethereum.selectedAddress;

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  themeMode: "dark",
  allWallets: "SHOW",
  themeVariables: {
    "--w3m-accent": "linear-gradient(rgb(19 107 127), rgb(6 141 114))",
    "--w3m-border-radius-master": "1.5px",
    "--w3m-button-border-color": "none",
  },
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // enableOnramp: true // Optional - false as default
});

function NavigationBar() {
  // useEffect(() => {
  //   const updateLocalStorage = (newAddress) => {
  //     if (newAddress) {
  //       localStorage.setItem("walletAddress", newAddress);
  //     }
  //   };

  //   // Update local storage when component mounts
  //   updateLocalStorage(window.ethereum.selectedAddress);

  //   // Update local storage when address changes
  //   const handleAccountsChanged = (accounts) => {
  //     updateLocalStorage(accounts[0]);
  //   };
  //   window.ethereum.on("accountsChanged", handleAccountsChanged);

  //   // Cleanup
  //   return () => {
  //     window.ethereum.off("accountsChanged", handleAccountsChanged);
  //   };
  // }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidear = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    // <Navbar expand="lg" style={{ background: "black", padding: "1rem 2.5rem" }}>
    //   <Navbar.Brand>
    //     <Link to="/">
    //       <img src={logo} alt="logo" style={{ width: "10rem" }}></img>
    //     </Link>
    //   </Navbar.Brand>
    //   <Navbar.Toggle />
    //   <Navbar.Collapse id="navbarScroll">
    //     <Nav className="ms-auto">
    //       <NavLink
    //         to="/createtoken"
    //         className={({ isActive }) =>
    //           `me-5 mb-3 mb-lg-0 link ${isActive ? "activeLink" : ""}`
    //         }
    //       >
    //         Create Token
    //       </NavLink>
    //       {/* <NavLink
    //           to="/dashboard"
    //           className={({ isActive }) =>
    //             `mx-2 link ${isActive ? "activeLink" : ""}`
    //           }
    //         >
    //           Dashboard
    //         </NavLink> */}
    //     </Nav>

    //     <WagmiProvider config={config}>
    //       <div className="d-lg-inline-flex align-items-lg-center">
    //         <w3m-network-button style={{ marginRight: "1rem" }} />
    //         <div className="mt-lg-0 mt-3">
    //           <w3m-button balance="hide" />
    //         </div>
    //       </div>
    //     </WagmiProvider>
    //   </Navbar.Collapse>
    // </Navbar>
    <nav className="navbar-container py-3 flex sticky-top">
      <div>
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
      </div>
      {/* NAv for small Devices */}
      <div className="d-lg-none">
        <HiBars3 color="white" size="1.5rem" onClick={handleSidear} />
      </div>
      {isSidebarOpen && (
        <div className="navbar-sidebar">
          <div className="d-flex justify-content-end m-3">
            <HiXMark size="1.5rem" onClick={handleSidear} />
          </div>
          <ul>
            <li>
              <NavLink
                to="/createtoken"
                className={({ isActive }) =>
                  `link ${isActive ? "activeLink" : ""}`
                }
              >
                Create Token
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `mx-2 link ${isActive ? "activeLink" : ""}`
                }
              >
                Dashboard
              </NavLink>
            </li>
            <div>
              <WagmiProvider config={config}>
                <div className="d-lg-inline-flex align-items-lg-center">
                  <w3m-network-button style={{ marginRight: "1rem" }} />
                  <div className="mt-lg-0 mt-3">
                    <w3m-button balance="hide" />
                  </div>
                </div>
              </WagmiProvider>
            </div>
          </ul>
        </div>
      )}

      {/* Nav for Large Devices */}
      <ul className="d-none d-lg-flex justify-content-md-between mt-3">
        <div className="d-flex align-items-center gap-md-3 gap-lg-3">
          <li>
            <NavLink
              to="/createtoken"
              className={({ isActive }) =>
                `link ${isActive ? "activeLink" : ""}`
              }
            >
              Create Token
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                ` mx-3 link ${isActive ? "activeLink" : ""}`
              }
            >
              Dashboard
            </NavLink>
          </li>
          <div>
            <WagmiProvider config={config}>
              <div className="d-lg-inline-flex align-items-lg-center">
                <w3m-network-button style={{ marginRight: "1rem" }} />
                <div className="mt-lg-0 mt-3">
                  <w3m-button balance="hide" />
                </div>
              </div>
            </WagmiProvider>
          </div>
        </div>
      </ul>
    </nav>
  );
}

export default NavigationBar;

import React from "react";
import InputHeader from "./InputHeader";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";
import InputGroup from "./InputGroup";
import CopyText from "./CopyText/CopyText";

const EnableTradingFunction = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Enable Trading function</p>
      <InputHeader
        label="Trading status"
        subHeading="tradingEnabled( ) - Is trading enabled for everyone?"
      />
      <p style={{ color: "#0AE7FE" }}>False (No)</p>
      {/* Enable Trading   */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Enable Trading "
          subHeading="enableTrading( ) - Permanently enable trading"
          ButtonLabel="Permanently enable trading"
          margin="my-0"
        />
      </OwnerOnlyWrapper>
      {/* Is excluded from trading restriction? */}
      <InputGroup
        Label="Is excluded from trading restriction?"
        subHeading="isExcludedFromTradingRestriction( address ) - Check if address is excluded from the trading restriction before trading is enabled. After enabling trading this exclusions no longer apply."
        InputLabel1="Address"
        InputPlaceHolder1="0x......."
        ButtonLabel="Is excluded from trading restriction?"
      />
      {/* Exclude from trading restriction */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Exclude from trading restriction"
          subHeading="excludeFromTradingRestriction( address, isExcluded ) - Excludes or includes address in trading restriction"
          InputLabel1="Address"
          InputPlaceHolder1="0x"
          OptionLabel="Excluded status"
          Options={["True (Included)", "False (Excluded)"]}
          ButtonLabel="Excluded from trading restriction"
          margin="my-0"
        />
      </OwnerOnlyWrapper>
      <InputHeader
        label="Excluded from TRADING RESTRICTION by default"
        subHeading="Below addresses were automatically excluded to ensure proper token functionality. Including them might result in huge stability problems and errors. Don't include them unless you know what you are doing!"
      />
      <CopyText contactaddress={"0x4f0A48301add"} label={"Supply recipient"} />
      <CopyText
        contactaddress={"0x35c68bFd0b55793"}
        label={"Token smart contract"}
      />
    </div>
  );
};

export default EnableTradingFunction;

import InputGroup from "./InputGroup";

const General = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">General (ERC -20)</p>
      {/* Balance Of Part */}
      <InputGroup
        Label="Balance of"
        subHeading={"balanceOf( address ) - Check balance of address"}
        InputLabel1="Address"
        InputPlaceHolder1="0x......."
        ButtonLabel="Check Balance"
      />
      {/* Transfer Part */}
      <InputGroup
        Label="Transfer"
        subHeading="transfer( to, amount ) - Transfer your AT"
        InputLabel1="To"
        InputPlaceHolder1="0x......."
        InputLabel2="Amount"
        InputPlaceHolder2="123,456"
        ButtonLabel="Transfer"
      />
      {/* Burn Part */}
      <InputGroup
        Label="Burn"
        subHeading={
          "burn( amount ) - Burn your AT by sending them to 0x0 address and decrease total supply"
        }
        InputLabel1="Amount"
        InputPlaceHolder1="123,456"
        ButtonLabel="Burn"
      />
      {/* Allowance */}
      <InputGroup
        Label="Allowance"
        subHeading={
          "allowance( owner, spender ) - Check approved token amount by Owner for Spender"
        }
        InputLabel1="Owner"
        InputLabel2="Spender"
        InputPlaceHolder1="0x......."
        InputPlaceHolder2="0x..."
        ButtonLabel="Check Allowance"
      />
      {/* Approve */}
      <InputGroup
        Label="Approve"
        subHeading={
          "approve( spender, amount ) - Approve Spender to spend your AT"
        }
        InputLabel1="Spender"
        InputLabel2="Amount"
        InputPlaceHolder1="0x......."
        InputPlaceHolder2="123,456"
        ButtonLabel="Approve"
      />
    </div>
  );
};

export default General;

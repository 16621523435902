import React from "react";
import InputGroup from "./InputGroup";
import InputHeader from "./InputHeader";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";
import CopyText from "./CopyText/CopyText";
const AntiBotCooldown = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Anti-bot cooldown</p>
      <InputHeader
        label="Trade cooldown time"
        subHeading="tradeCooldownTime( ) - Period of time each user must wait after buy or sell to be unfrozen"
      />
      <p style={{ color: "#0AE7FE" }}>10 Seconds</p>
      {/* Update trade  */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Update trade cooldown time"
          subHeading="updateTradeCooldownTime( cooldownTime ) - Change the trade cooldown time"
          InputLabel1="Cooldown Time"
          InputPlaceHolder1="60"
          OptionLabel=" "
          Options={["Seconds", "Minutes", "Hours"]}
          ButtonLabel="Update trade cooldown time"
        />
      </OwnerOnlyWrapper>
      {/* Last Trade */}
      <InputGroup
        Label="Last trade"
        subHeading="lastTrade( address ) - Check the most recent time when the provided address bought or sold AT"
        InputLabel1="Address"
        InputPlaceHolder1="0x......."
        ButtonLabel="Check Last Trade"
      />
      {/* Is excluded from limits? */}
      <InputGroup
        Label="Is excluded from limits?"
        subHeading="isExcludedFromLimits( address ) - Check if address is excluded from limits. This exclusion affects all similar features in your token (Max amount per wallet, Max transaction limits & Anti-bot cooldown) depending whether you have them."
        InputLabel1="Address"
        InputPlaceHolder1="0x"
        ButtonLabel="Is excluded from limits?"
      />
      {/* Exclude from limits  */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Exclude from limits"
          subHeading="excludeFromLimits( address, isExcluded ) - Excludes or includes address in limit features."
          InputLabel1="Address"
          InputPlaceHolder1="0x"
          OptionLabel="Excluded status"
          Options={["True (Included)", "False (Excluded)"]}
          ButtonLabel="Exclude from limits"
        />
      </OwnerOnlyWrapper>
      <InputHeader
        label="Excluded from LIMITS by default"
        subHeading="Below addresses were automatically excluded to ensure proper token functionality. Including them might result in huge stability problems and errors. Don't include them unless you know what you are doing!"
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"Supply recipient"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793"}
        label={"Token smart contract"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"0x0 (burn) address"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"#wallet tax recipient"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"DEX Router smart contract"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"DEX Pair smart contract"}
      />
    </div>
  );
};

export default AntiBotCooldown;

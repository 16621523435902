import React, { useEffect, useState } from "react";
import CircleIcon from "../../../Images/Logos/Circle.svg";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { truncateText } from "../../../Utils/helperFunction";

const PaymentCard = ({ data }) => {
  const { tokenname, walletaddress, id, tokensymbol, paymentchainid } = data;
  // const tokenName = localStorage.getItem("tokenName") || "";
  // const WalletAddress = localStorage.getItem("walletAddress") || "";
  // const ID = localStorage.getItem("ID") || "";

  const [network, setNetwork] = useState("");
  useEffect(() => {
    const getNetworkName = (paymentchainid) => {
      switch (paymentchainid) {
        case "1" || "1n" || "0x1": // Polygon Mumbai chain ID
          return "Ethereum Mainnet";
          break;
        case "97" || "97n" || "0x61": // Polygon Mainnet chain ID
          return "BNB Smart Chain Testnet";
          break;
        case "137" || "137n" || "0x89": // Polygon Mainnet chain ID
          return "Polygon Mainnet";
          break;
        case "43114" || "43114n" || "0xa86a": // Avalanche Mainnet chain ID
          return "Avalanche C-Chain";
          break;
        case "10" || "10n" || "0xa": // Opium Mainnet chain ID
          return "OP Mainnet";
          break;
        case "43113" || "43113n" || "0xa869": // Avalanche Testnet chain ID
          return "Avalanche FUJI";
          break;
        case "421614" || "421614n" || "0x66eee": // Arbitrum Sepolia Testnet chain ID
          return "Arbitrum Sepolia";
          break;
        case "42161" || "42161n" || "0xa4b1": // Arbitum Testnet chain ID
          return "Arbitrum One";
          break;
        case "56" || "56n" || "0x38": // BSC Testnet chain ID
          return "BNB Smart Chain Mainnet";
          break;
        case "42220" || "42220n" || "0xa4ec": // Celo Testnet chain ID
          return "Celo Mainnet";
          break;
        case "8453" || "8453n" || "0x2105": // Base Testnet chain ID
          return "Base";
          break;
        case "250" || "250n" || "0xfa": // Fantom chain ID
          return "Fantom Opera";
          break;
        case "84532" || "84532n" || "0x14a34": // Base Sepolia Testnet chain ID
          return "Base Sepolia Testnet";
          break;
        default:
          return "Unknown Network";
      }
    };
    setNetwork(getNetworkName(paymentchainid));
  }, [paymentchainid]);
  // Function for download file
  const [buttonText, setButtonText] = useState("DOWNLOAD");
  const [isDownloaded, setIsDownloaded] = useState(false);
  const handleDownload = async () => {
    setButtonText("DOWNLOADING....");
    try {
      const response = await fetch(
        `https://trendifytokens.io/api/files/getdshboarddownloadfiles?tokenname=${tokenname}&walletaddress=${walletaddress}&id=${id}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // Set the filename with .zip extension
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = `${tokenname}.zip`; // Default filename with .zip extension
      if (contentDisposition && contentDisposition.includes("attachment")) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition
        );
        if (matches != null && matches[1]) {
          filename =
            matches[1].replace(/['"]/g, "").replace(/\.[^/.]+$/, "") + ".zip";
        }
      } else {
        filename = `${tokenname}.zip`; // Fallback filename
      }
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsDownloaded(true);
      setButtonText("Downloaded");
    } catch (error) {
      setButtonText("Download Failed");
      console.log(error);
    }
  };

  // State for Disable Download Button for (Testnet)
  // const [testNetDownloadDisable, setTestNetDownloadDisable] = useState(false);

  // useEffect(() => {
  //   switch (paymentchainid) {
  //     case "84532" || "84532n" || "0x14a34":
  //       setTestNetDownloadDisable(true);
  //       break;

  //     case "97" || "97n" || "0x61":
  //       setTestNetDownloadDisable(true);
  //       break;

  //     default:
  //       setTestNetDownloadDisable(false);
  //       break;
  //   }
  // }, [paymentchainid]);
  return (
    <Col
      sm={12}
      md={8}
      lg={5}
      className="my-3 p-4"
      style={{
        borderRadius: "16px",
        background: "rgba(255, 255, 255, 0.10)",
      }}
    >
      {/* Token Header */}
      <div className="mb-3">
        <div className="d-flex align-items-center gap-2 mb-4">
          <div>
            <img src={CircleIcon} alt="circleicon" />
          </div>
          <p
            className="zeroMargin fs-5"
            style={{ color: "#FFF", wordBreak: "break-word" }}
          >
            {tokenname} ({tokensymbol})
          </p>
        </div>
        <div className="d-flex item-center justify-content-between my-1">
          <p className="zeroMargin" style={{ color: "#D7D7D7" }}>
            Deployer
          </p>
          <p className="zeroMargin" style={{ color: "#F1F1F1" }}>
            {truncateText(walletaddress)}
          </p>
        </div>
        <div className="d-flex item-center justify-content-between my-1">
          <p className="zeroMargin" style={{ color: "#D7D7D7" }}>
            Network
          </p>
          <p className="zeroMargin" style={{ color: "#F1F1F1" }}>
            {network}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row gap-2">
        <button
          className="text-decoration-none px-4 text-center py-2 btn-bg-color mt-4 text-light border-0 rounded-2 w-100"
          style={{
            background: `${
              buttonText === "DOWNLOADING FOR AUDIT" ? "#17171B" : ""
            }`,
            opacity: `${buttonText === "DOWNLOADING FOR AUDIT" ? "0.5" : "1"}`,
          }}
          onClick={handleDownload}
        >
          {buttonText}
        </button>
      </div>
    </Col>
  );
};

export default PaymentCard;

import React from "react";
import Logo from "../../Images/Home/logo.png";
import { Link } from "react-router-dom";
import Instagram from "../../Images/Home/instagram.svg";
import Twitter from "../../Images/Home/twitter.svg";
import Telegram from "../../Images/Home/telegram.svg";
import Github from "../../Images/Home/gihub.svg";
const Footer = () => {
  return (
    <footer className="footer p-3 p-lg-4">
      {/* Main Container */}
      <div className="footer-container d-flex justify-content-between flex-column flex-md-row gap-3">
        {/* Footer Logo */}
        <div>
          <a href="/">
            <img src={Logo} alt="logo" className="logo mb-3 mb-md-5" />
          </a>
          <p className="text-light">Copyright by 2024 - Trendifytokens.io</p>
        </div>
        {/* <div className="d-flex flex-column gap-2">
          <Link>About</Link>
          <Link>Career on Trendpad</Link>
          <Link>Contact us </Link>
          <Link>Trendtime Overview</Link>
          <Link>Updates</Link>
        </div>
        <div className="d-flex flex-column gap-2">
          <Link>Terms of Service</Link>
          <Link>Privacy Policy</Link>
          <Link>Cookie Policy</Link>
        </div> */}
        <div className="d-flex flex-lg-row gap-3">
          <a href="https://t.me/trendavatars" target="_blank">
            <img src={Telegram} alt="" />
          </a>
          <a href="https://x.com/TTAvatars" target="_blank">
            <img src={Twitter} alt="" />
          </a>
          {/* <Link className="github-icon">
            <img src={Github} alt="" />
          </Link> */}
          {/* <Link>
            <img src={Instagram} alt="" />
          </Link> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

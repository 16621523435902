import { Col, Form, Row } from "react-bootstrap";
import Input from "./Input";

function TaxInput({
  Label,
  Description,
  InputPlaceholder,
  Options = [],
  Placeholder,
  Name,
  value,
  onChange,
  selectName,
  selectValue,
  selectOnChange,
  InputDescription,
  gasAutoClaimError,
  dividendEligibilityAmountError,
  autoClaimIntervalError,
  swapRatioInputError,
  percentage,
}) {
  return (
    <>
      <Form.Group as={Row}>
        <Col sm={12} md={6} className="text-white">
          <Form.Label>{Label}</Form.Label>
          <div>
            <p style={{ color: "#737373" }}>{Description}</p>
          </div>
        </Col>
        <Col sm={12} md={6}>
          {/* {Label === "Auto-claim interval" ? (
            <>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Control
                    type="text"
                    placeholder={InputPlaceholder}
                    className="bg-transparent mt-2"
                    style={{ color: "#F1F1F1", borderColor: "#3A3A3A" }}
                  />
                  <Form.Text style={{ color: "#737373" }}>
                    1 minute ≤ Auto-claim interval ≤ 7 days
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Select
                    style={{ color: "#737373", margin: "0.5rem 0" }}
                    className="bg-dark"
                    as="select"
                    placeholder={Placeholder}
                    id="InputText"
                  >
                    {Options.map((option, index) => (
                      <option key={index}>{option}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
            </>
          ) : (
            <>
              <Form.Control
                type="text"
                placeholder={InputPlaceholder}
                className="bg-transparent mt-2"
                style={{ color: "#F1F1F1", borderColor: "#3A3A3A" }}
              />
              <Form.Text style={{ color: "#737373" }}>
                {InputDescription}
              </Form.Text>
            </>
          )} */}

          {/* Optimized Code  */}
          <Row className="mb-3">
            <Form.Group as={Col}>
              <div style={{ position: percentage ? "relative" : "" }}>
                <Form.Control
                  type="text"
                  placeholder={InputPlaceholder}
                  className="bg-transparent mt-2 placeholderColor"
                  style={{ color: "#F1F1F1", borderColor: "#3A3A3A" }}
                  name={Name}
                  onChange={onChange}
                  value={value}
                />
                {percentage && (
                  <p
                    style={{
                      position: "absolute",
                      top: "0.5rem",
                      right: "0.5rem",
                      color: "#737373",
                    }}
                  >
                    %
                  </p>
                )}
              </div>
              <Form.Text
                style={{
                  color:
                    gasAutoClaimError ||
                    dividendEligibilityAmountError ||
                    autoClaimIntervalError ||
                    swapRatioInputError
                      ? "red"
                      : "#737373",
                }}
              >
                {InputDescription}
              </Form.Text>
            </Form.Group>

            {Label === "Auto-claim interval" && (
              <Form.Group as={Col}>
                <Form.Select
                  style={{
                    color: "#737373",
                    margin: "0.5rem 0",
                    borderColor: "#3A3A3A",
                  }}
                  className="bg-transparent placeholderColor"
                  as="select"
                  placeholder={Placeholder}
                  id="InputText"
                  name={selectName}
                  onChange={selectOnChange}
                  value={selectValue}
                >
                  {Options.map((option, index) => (
                    <option key={index} className="bg-dark">
                      {option}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </Row>
        </Col>
      </Form.Group>
    </>
  );
}

export default TaxInput;

import React from "react";
import { Row } from "react-bootstrap";
import HeroImg from "../../Images/Home/herologo.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="home-wrapper" id="home">
      <div className="d-flex flex-column gap-5 gap-md-1 flex-md-row justify-content-md-between align-items-md-center text-center text-md-start mx-3 mx-lg-3">
        <div>
          <p className="text-light mb-4">
            Introducing Multi Chain Token Creator
          </p>
          <h1 className="text-light hero-heading">
            Create a fully <br className="d-none d-md-block" />
            customizable token
          </h1>
          <p style={{ color: "#F1F1F1" }} className="mb-5">
            Create and manage your own unique cryptocurrency tokens across
            <br className="d-none d-lg-block" />
            multiple blockchain networks with ease using our innovative Multi
            Chain Token Creator.
          </p>
          <div>
            <Link className="token-btn" to="/createtoken">
              Create Token
            </Link>
          </div>
        </div>
        <div>
          <img src={HeroImg} alt="hero" className="heroImg" />
        </div>
      </div>
    </section>
  );
};

export default Hero;

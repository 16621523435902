import React from "react";
import SmartContractStepper from "../Components/Forms/SmartContractStepper";
function CreateTokens() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <SmartContractStepper />
    </div>
  );
}

export default CreateTokens;

import React, { useState } from "react";
import Circle from "../../../Images/Logos/Circle.svg";
import CopyIcon from "../../../Images/Logos/copy.svg";
import PolygonIcon from "../../../Images/Network Logo/Polygon.svg";
import walletIcon from "../../../Images/Logos/wallet.svg";
import infoIcon from "../../../Images/Logos/info.svg";
import addressIcon from "../../../Images/Logos/addressIcon.png";
import Exclaimation from "../../../Images/Logos/exclaimation.svg";
import { Col } from "react-bootstrap";
import { formatInteger } from "../../../Utils/helperFunction";
// import CheckSuccess from "../../../Images/Logos/DeployedSuccess.svg";
import { HiMiniCheck } from "react-icons/hi2";

const TokenHeaderInfo = ({ data }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(data?.contactaddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };
  return (
    <Col sm={12} className="my-3 p-4 dashboard-primary-bg rounded-3">
      {/* FIrst Row */}
      <div className="d-flex flex-column gap-4 gap-md-0 flex-md-row align-items-start justify-content-between mb-4">
        {/* Left */}
        <div>
          <div className="d-flex align-items-center gap-2">
            <div>
              <img src={Circle} alt="circle" />
            </div>
            <p className="text-white">
              {data?.tokenname} ({data?.tokensymbol})
            </p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <p
              className="text-gray text-wrap"
              style={{ wordBreak: "break-word" }}
            >
              {data?.contactaddress}
            </p>

            {copied ? (
              <HiMiniCheck color="white" size="1.2rem" />
            ) : (
              <img
                src={CopyIcon}
                alt="copyIcon"
                onClick={handleCopy}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        </div>
        {/* Right */}
        <div className="d-flex align-items-center gap-2">
          <div>
            <img src={PolygonIcon} alt="polygonicon" />
          </div>
          <p className="text-white">Polygon Mumbai</p>
        </div>
      </div>
      {/* Second Row */}
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 gap-md-0">
        {/* Left */}
        <div>
          {/* Left First */}
          <div className="d-flex flex-column flex-md-row  gap-4 mb-3">
            <div>
              <p className="text-gray">Decimals</p>
              {data?.decimals ? (
                <p className="text-white">{data?.decimals}</p>
              ) : (
                <p className="text-white">-</p>
              )}
            </div>
            <div>
              <p className="text-gray">Total Supply</p>
              {data?.initialsupply ? (
                <p className="text-white">
                  {formatInteger(data?.initialsupply)}
                </p>
              ) : (
                <p className="text-white">-</p>
              )}
            </div>
            <div>
              <p className="text-gray">Viewing as</p>
              <p className="text-white">Owner</p>
            </div>
          </div>
          {/* Left Second */}
          <div className="d-flex align-items-center gap-3">
            <div
              style={{
                padding: "0.5rem",
                background: "#1A3249",
                borderRadius: "99px",
                cursor: "pointer",
              }}
            >
              <img src={addressIcon} alt="addressIcon" />
            </div>
            <div
              style={{
                padding: "0.6rem",
                background: "#1A3249",
                borderRadius: "99px",
                cursor: "pointer",
              }}
            >
              <img src={walletIcon} alt="walletIcon" />
            </div>
            <div
              style={{
                padding: "0.4rem",
                background: "#1A3249",
                borderRadius: "99px",
                cursor: "pointer",
              }}
            >
              <img src={infoIcon} alt="infoIcon" />
            </div>
          </div>
        </div>
        {/* Right */}
        <div
          style={{
            background: "rgba(255, 255, 255, 0.10)",
            borderRadius: "16px",
          }}
          className="d-flex align-items-center gap-2 p-3"
        >
          <div>
            <img src={Exclaimation} alt="ExclaimationIcon" />
          </div>
          <div>
            <p className="text-white">Verification on PolygonScan</p>
            <p className="text-gray">
              Verification is not available on Testnets.
            </p>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default TokenHeaderInfo;

import Modal from "react-bootstrap/Modal";
import Ratio from "react-bootstrap/Ratio";

const VideoModal = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="custom-modal-header"
        ></Modal.Header>
        <Modal.Body className="custom-modal-body">
          <Ratio aspectRatio={"16x9"}>
            <iframe
              width="1280"
              height="720"
              src="https://player.vimeo.com/video/1002708542"
              title="Elementor video placeholder"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Ratio>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default VideoModal;

import { Col, Row } from "react-bootstrap";
import CopyIcon from "../../../Images/Logos/copy.svg";
import { HiMiniCheck } from "react-icons/hi2";
import { useState } from "react";

const CopyText = ({ contactaddress, label }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(contactaddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };
  return (
    <Col className="d-flex align-items-center justify-content-between my-1">
      <p style={{ color: "#D7D7D7" }}>{label}</p>
      <div className="d-flex align-items-center gap-2">
        <p className="text-gray text-wrap" style={{ wordBreak: "break-word" }}>
          {contactaddress}
        </p>

        {copied ? (
          <HiMiniCheck color="white" size="1.5rem" />
        ) : (
          <img
            src={CopyIcon}
            alt="copyIcon"
            onClick={handleCopy}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </Col>
  );
};

export default CopyText;

import React from "react";
import Divider from "../divider";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
const PermitInputGroup = ({
  Label,
  subHeading,
  InputLabel1,
  InputLabel2,
  InputLabel3,
  InputLabel4,
  InputLabel5,
  InputLabel6,
  InputLabel7,
  InputPlaceHolder1,
  InputPlaceHolder2,
  InputPlaceHolder3,
  InputPlaceHolder4,
  InputPlaceHolder5,
  InputPlaceHolder6,
  InputPlaceHolder7,
  ButtonLabel,
  ButtonColor = "linear-gradient(224deg, rgba(54, 153, 178, 0.50) 0%, rgba(16, 207, 163, 0.50) 94.72%)",
  margin = "mt-4",
}) => {
  return (
    <div className={`${margin}`}>
      <p className="gray-text">{Label}</p>
      <Divider />
      <p className="gray-text mb-2">{subHeading}</p>
      <Row>
        {InputLabel1 && (
          <Col sm={12}>
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel1}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder1}
            />
          </Col>
        )}

        {InputLabel2 && (
          <Col sm={12} className="mt-3">
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel2}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder2}
            />
          </Col>
        )}
        {InputLabel3 && (
          <Col sm={12} className="mt-3">
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel3}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder3}
            />
          </Col>
        )}
        {InputLabel4 && (
          <Col sm={12} className="mt-3">
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel4}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder4}
            />
          </Col>
        )}
        {InputLabel5 && (
          <Col sm={12} className="mt-3">
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel5}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder5}
            />
          </Col>
        )}
        {InputLabel6 && (
          <Col sm={12} className="mt-3">
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel6}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder6}
            />
          </Col>
        )}
        {InputLabel7 && (
          <Col sm={12} className="mt-3">
            <FormLabel
              className={`d-flex align-items-start`}
              style={{ color: "#F1F1F1" }}
            >
              {InputLabel7}
            </FormLabel>

            <Form.Control
              className="bg-transparent mt-2 placeholderColor"
              style={{ color: "#FFF", borderColor: "#3A3A3A" }}
              type="text"
              placeholder={InputPlaceHolder7}
            />
          </Col>
        )}
      </Row>
      <button
        className="px-3 py-2 rounded-2 text-white mt-3 dashboard-btn"
        style={{
          background: ButtonColor,
          border: "none",
        }}
      >
        {ButtonLabel}
      </button>
    </div>
  );
};

export default PermitInputGroup;

import React from "react";
import Divider from "./divider";

const InputHeader = ({ label, subHeading }) => {
  return (
    <div className="my-3">
      <p className="gray-text">{label}</p>
      <Divider />
      <p className="gray-text mb-2">{subHeading}</p>
    </div>
  );
};

export default InputHeader;

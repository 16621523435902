import Logo from "../../Images/Home/logo.png";
import { Link } from "react-router-dom";
import { HiBars3, HiXMark } from "react-icons/hi2";
import { useEffect, useState } from "react";
const Navigation = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidear = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <nav className="navigation-container py-3 flex sticky-top">
        <div>
          <a href="#">
            <img src={Logo} alt="logo" className="logo" />
          </a>
        </div>
        {/* NAv for small Devices */}
        <div className="d-lg-none">
          <HiBars3 color="white" size="1.5rem" onClick={handleSidear} />
        </div>
        {isSidebarOpen && (
          <div className="mobile-sidebar">
            <div className="d-flex justify-content-end m-3">
              <HiXMark size="1.5rem" onClick={handleSidear} />
            </div>
            <ul>
              <li onClick={handleSidear}>
                <a href="#">Home</a>
              </li>
              <li onClick={handleSidear}>
                <a href="#feature">Features</a>
              </li>
              <li onClick={handleSidear}>
                <a href="#statistics">Statistics</a>
              </li>
              <li onClick={handleSidear}>
                <a href="#products">Products</a>
              </li>
              <li onClick={handleSidear}>
                <a href="#pricing">Pricing</a>
              </li>
              <div>
                <Link to="/createtoken" className="launch-btn">
                  Launch app
                </Link>
              </div>
            </ul>
          </div>
        )}

        {/* Nav for Large Devices */}
        <ul className="d-none d-lg-flex justify-content-md-between mt-3">
          <div className="d-flex align-items-center gap-md-3 gap-lg-5">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#feature">Features</a>
            </li>
            <li>
              <a href="#statistics">Statistics</a>
            </li>
            <li>
              <a href="#products">Products</a>
            </li>
            <li>
              <a href="#pricing">Pricing</a>
            </li>
          </div>
          <div>
            <Link to="/createtoken" className="launch-btn">
              Launch app
            </Link>
          </div>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;

import React from "react";
import Network1 from "../../Images/Home/network1.png";
import Network2 from "../../Images/Home/network2.png";
import Network3 from "../../Images/Home/network3.png";
import Network4 from "../../Images/Home/network4.png";
import Network5 from "../../Images/Home/network5.png";
import Network6 from "../../Images/Home/network6.png";
import Network7 from "../../Images/Home/network7.png";
import Network8 from "../../Images/Home/network8.png";
import Network9 from "../../Images/Home/network9.png";

const PoweredBy = () => {
  const imgArr = [
    Network1,
    Network2,
    Network3,
    Network4,
    Network5,
    Network6,
    Network7,
    Network8,
    Network9,
  ];
  return (
    <section className="home-wrapper">
      <div className="mx-3 py-3">
        <p className="text-light text-center text-md-start my-4 ms-lg-5 ">
          Powered by
        </p>
        <div className="d-flex align-items-center justify-content-center flex-wrap gap-5">
          {imgArr.map((img) => (
            <img src={img} alt={img} key={img} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PoweredBy;

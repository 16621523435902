import { createSlice } from "@reduxjs/toolkit";

const GeneralSlice = createSlice({
  name: "general",
  initialState: {
    tokenName: "",
    walletAddress: "",
    tokenSymbol: "",
    decimal: 18,
    initialSupply: "",
    mintable: false,
    mintableInput: "",
    supplyrecipitent: false,
    tokenowner: false,
    supplyrecipitentInput: "",
    tokenOwnerInput: "",
  },
  reducers: {
    updateFormData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateSwitchInput: (state, action) => {
      const { name, checked } = action.payload;
      state[name] = checked;
    },
    // updateWalletAddress: (state, action) => {
    //   state.walletaddress = action.payload;
    // },
    resetFormData: (state) => {
      state.tokenName = "";
      state.tokenSymbol = "";
      state.decimal = 18;
      state.initialSupply = "";
      state.mintable = false;
      state.mintableInput = "";
      state.supplyrecipitent = false;
      state.tokenowner = false;
      state.supplyrecipitentInput = "";
      state.tokenOwnerInput = "";
    },
  },
});

export const {
  updateFormData,
  updateSwitchInput,
  updateWalletAddress,
  resetFormData,
} = GeneralSlice.actions;
export default GeneralSlice.reducer;
